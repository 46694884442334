import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, TextField, Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { Formik } from 'formik';
import { useLocale, useIsMount, usePrevious } from '@sonar-web/common/src/hooks';
import {
	addDeviceTypeAsync,
	setDeviceTypeData,
	selectDeviceType,
	fetchDeviceTypeAsync,
	editDeviceTypeAsync,
	selectDeviceTypeAdd,
	resetDeviceTypeData,
	resetAddSuccess
} from './wmbusDeviceTypeSlice';
import { FormTopActionsToolbar, BaseGrowAnimation, guidEmpty } from '@sonar-web/common';
import { ERROR_TYPES } from '@sonar-web/common/src/constants/errorTypes';
import validationSchema from './validationSchema';
import DeviceTypeSkeleton from './DeviceTypeSkeleton';
import CheckboxCheckedIcon from '@sonar-web/common/src/icons/CheckboxCheckedIcon';
import CheckboxUncheckedIcon from '@sonar-web/common/src/icons/CheckboxUncheckedIcon';

const DeviceTypeForm = ({ id, readOnly, clearOnClose = true, onClose, onSuccessCallback }) => {
	const { translate } = useLocale();

	const dispatch = useDispatch();
	const nameInput = useRef(null);
	const isMount = useIsMount();
	const previousId = usePrevious(id);
	const { addPending, addSuccess } = useSelector(selectDeviceTypeAdd);
	const { dataRow, fetchSuccess } = useSelector(selectDeviceType);
	const [submitMany, setSubmitMany] = useState(false);

	let title = id ? 'Devices_EditDeviceType' : 'Devices_AddDeviceType';
	if (readOnly) title = 'Devices_DeviceType';

	const submitFunction = id ? editDeviceTypeAsync : addDeviceTypeAsync;

	const handleClose = () => onClose();

	const handleApiErrors = (err, formikActions) => {
		formikActions.setSubmitting(false);

		if (err && err.type === ERROR_TYPES.api) {
			formikActions.setFieldTouched(err.member.uncapitalize(), true);
			setTimeout(() => formikActions.setFieldError(err.member.uncapitalize(), err.message), 100);
		}
	};

	const handleOnSubmit = async (values, actions) => {
		actions.setSubmitting(true);

		dispatch(setDeviceTypeData(values));
		dispatch(submitFunction(submitMany)).then((err) => handleApiErrors(err, actions));
	};

	const handleResetSuccess = () => {
		if (submitMany) {
			dispatch(resetDeviceTypeData({ isInSubmitManyMode: true }));
			nameInput.current.focus();
		}
		dispatch(resetAddSuccess());
	};

	const sliceCleanup = () => {
		if (clearOnClose) dispatch(resetDeviceTypeData());
	};

	useEffect(() => {
		if (id && id !== previousId) {
			if ((isMount && (!dataRow.id || dataRow.id === guidEmpty)) || (!isMount && previousId))
				dispatch(fetchDeviceTypeAsync(id));
		}

		return () => sliceCleanup();
	}, [id]);

	const toInputUppercase = (e) => {
		e.target.value = ('' + e.target.value).toUpperCase();
	};

	return !id || (id && fetchSuccess) ? (
		<BaseGrowAnimation show={true}>
			<Box flex='1' alignSelf={readOnly ? 'flex-start' : 'center'} width={500} data-at='DeviceTypeForm'>
				<Formik
					initialValues={dataRow}
					enableReinitialize
					validationSchema={validationSchema}
					onSubmit={handleOnSubmit}>
					{(formikActions) => {
						const { handleSubmit, handleChange, handleBlur, values, errors, touched } = formikActions;
						return (
							<form onSubmit={handleSubmit}>
								{!readOnly && (
									<FormTopActionsToolbar
										title={title}
										handleCancel={handleClose}
										handleSubmit={handleSubmit}
										pending={addPending}
										success={addSuccess}
										onSuccess={() => {
											if (onSuccessCallback) {
												setTimeout(() => onSuccessCallback(addSuccess, submitMany), 300);
											}
										}}
										resetSuccess={handleResetSuccess}
										submitMany={submitMany}
										setSubmitMany={setSubmitMany}
										disableSubmitMany={id != null}
									/>
								)}
								<TextField
									InputProps={{
										readOnly: readOnly,
										disableUnderline: readOnly
									}}
									inputRef={nameInput}
									margin='normal'
									name='name'
									label={translate('Devices_TypeName')}
									type='text'
									size='small'
									fullWidth
									value={values.name}
									onChange={handleChange}
									onBlur={handleBlur}
									error={errors.name && touched.name}
									helperText={errors.name && touched.name && translate(errors.name)}
								/>
								<TextField
									InputProps={{
										readOnly: readOnly,
										disableUnderline: readOnly
									}}
									margin='normal'
									name='hw'
									label={translate('Devices_Hw')}
									type='text'
									size='small'
									fullWidth
									value={values.hw}
									onChange={handleChange}
									onBlur={handleBlur}
									error={errors.hw && touched.hw}
									helperText={errors.hw && touched.hw && translate(errors.hw)}
								/>
								<TextField
									InputProps={{
										readOnly: readOnly,
										disableUnderline: readOnly
									}}
									margin='normal'
									name='sw'
									label={translate('Devices_Sw')}
									type='text'
									size='small'
									fullWidth
									value={values.sw}
									onChange={handleChange}
									onBlur={handleBlur}
									error={errors.sw && touched.sw}
									helperText={errors.sw && touched.sw && translate(errors.sw)}
								/>
								<TextField
									InputProps={{
										readOnly: readOnly,
										disableUnderline: readOnly
									}}
									margin='normal'
									name='mField'
									label={translate('Devices_MField')}
									type='text'
									size='small'
									fullWidth
									value={values.mField}
									onInput={toInputUppercase}
									onChange={handleChange}
									onBlur={handleBlur}
									error={errors.mField && touched.mField}
									helperText={errors.mField && touched.mField && translate(errors.mField)}
								/>
								<TextField
									InputProps={{
										readOnly: true,
										disableUnderline: true
									}}
									margin='normal'
									name='protocol'
									label={translate('Devices_Protocol')}
									type='text'
									size='small'
									fullWidth
									value={values.protocol}
									onChange={handleChange}
									onBlur={handleBlur}
									error={errors.protocol && touched.protocol}
									helperText={
										errors.protocol && touched.protocol && translate(errors.protocol)
									}></TextField>

								<FormControl fullWidth margin='normal'>
									<FormControlLabel
										control={
											<Checkbox
												size='small'
												color='primary'
												disableRipple
												checkedIcon={<CheckboxCheckedIcon />}
												icon={<CheckboxUncheckedIcon />}
												checked={values.isAesRequired}
												onChange={(v) => {
													if (!readOnly) {
														handleChange(v);
													}
												}}
												name={'isAesRequired'}
											/>
										}
										label={translate('Devices_IsAesRequired')}
									/>
								</FormControl>
							</form>
						);
					}}
				</Formik>
			</Box>
		</BaseGrowAnimation>
	) : (
		<Box flex='1' alignSelf='center' width={500}>
			<DeviceTypeSkeleton />
		</Box>
	);
};

export default DeviceTypeForm;
