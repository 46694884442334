import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
	appReducer,
	filtersReducer,
	errorsReducer,
	localesReducer,
	sidebarReducer,
	sortersReducer,
	deviceConfigProfilesReducer,
	deviceConfiguratorReducer,
	schedulesReducer,
	scheduleReducer
} from '@sonar-web/common';

import rolesSearchReducer from '@sonar-web/access/src/Roles/rolesSearchSlice';
import profileReducer from '@sonar-web/access/src/Profile/profileSlice';
import userReducer from '@sonar-web/access/src/User/userSlice';

import dashboardReducer from '@sonar/dashboard/src/dashboardSlice';

import {
	locationNodesReducer,
	locationNodeLevelsReducer,
	locationNodeReducer,
	LocationNodesMoveReducer,
	LocationNodeMoveReducer,
	LocationNodeReadsReducer,
	locationNodeReadsDevicesSearchReducer
} from '@sonar-web/location';

import { deviceReducer, deviceTypesSearchReducer, wmbusDeviceTypeReducer } from '@sonar-web/wmbus';

import {
	tcpDeviceReducer,
	tcpDeviceTypesSearchReducer,
	tcpDevicesSearchReducer,
	tcpDeviceTypesReducer
} from '@sonar-web/tcp';

import {
	watermeterReducer,
	watermeterTypesSearchReducer,
	watermeterReadReducer,
	watermeterQProfilesReducer,
	watermeterMonitReadsReducer,
	watermeterTypeReducer,
	waterMeterChartsReducer
} from '@sonar-web/watermeter';

import {
	heatallocatorReducer,
	heatAllocatorTypesSearchReducer,
	heatallocatorReadReducer,
	heatAllocatorChartsReducer
} from '@sonar/heatallocator';

import { statsLocationReducer, statisticsReducer } from '@sonar/stats';

import { importReducer, importWizardReducer } from '@sonar-web/import';
import createListSlice from '@sonar-web/common/src/features/List/genericListSlice';
import { exportConfiguratorReducer } from '@sonar/exports';
import { stationaryNetworkReducer } from '@sonar/stationary/src';
import {
	terminalDiagnosticReadsReducer,
	terminalDiagnosticReadsTimelineReducer,
	converterDiagnosticReadsTimelineReducer
} from '@sonar/stationary';

import systemSearchReducer from '@sonar-web/common/src/features/SystemSearch/systemSearchSlice';

import billingPeriodReducer from '@sonar/billing/src/Period/periodSlice';
import billingReducer from '@sonar/billing/src/Billing/billingSlice';
import billingClientReducer from '@sonar/billing/src/Client/billingClientSlice';

import { reportReducer } from '@sonar/reports';

import { configuratorReducer } from '@sonar/configurator';
import { licensesReducer, tenantReducer } from '@sonar/tenants';

import { devicesConfigurationsTypesReducer } from '@sonar/devices_configurations';

import { orderReducer, taskReducer } from '@sonar/orders';
import protocolTemplateSlice from '@sonar/orders/src/ProtocolTemplate/protocolTemplateSlice';
import contractorsSlice from '@sonar/orders/src/Contractors/contractorsSlice';

import eventsTypesSlice from '@sonar/events/src/Components/EventsFilter/eventTypesSlice';

import hubSlice from './mesh/hubSlice';
import hubDiagnosticSlice from './mesh/HubDiagnostic/DiagnosticReadsTimeline/hubDiagnosticSlice';
import networkSlice from './mesh/networkSlice';
import terminalSlice from './mesh/terminalSlice';
import meshTerminalTypesSearchSlice from './mesh/TerminalTypesFilter/meshTerminalTypesSearchSlice';

import setWizardSlice from './sets/setWizardSlice';
import setsSlice from './sets/setsSlice';
import customersSlice from './customers/customersSlice';

import heatMeterSlice from './HeatMeters/Heatmeter/heatMeterSlice';
import heatMeterReadSlice from './HeatMeters/Read/readSlice';
import heatMeterChartsSlice from './HeatMeters/Charts/heatMeterChartsSlice';

export default configureStore({
	middleware: getDefaultMiddleware({
		serializableCheck: {
			ignoredActions: ['auth/authSuccess', 'sidebar/toggleSidebar'],
			ignoredPaths: ['auth.keycloak', 'sidebar']
		}
	}),
	reducer: {
		app: appReducer,
		errors: errorsReducer,
		tenant: tenantReducer,
		licenses: licensesReducer,
		statistics: statisticsReducer,
		dashboard: dashboardReducer,
		sidebar: sidebarReducer,
		locales: localesReducer,
		systemSearch: systemSearchReducer,
		rolesSearch: rolesSearchReducer,
		filters: filtersReducer,
		sorters: sortersReducer,
		configurator: configuratorReducer,
		users: createListSlice('users'),
		userProfile: profileReducer,
		user: userReducer,
		locationNode: locationNodeReducer,
		locationNodes: locationNodesReducer,
		locationNodeLevels: locationNodeLevelsReducer,
		locationNodesMove: LocationNodesMoveReducer,
		locationNodeMove: LocationNodeMoveReducer,
		locationNodeReads: LocationNodeReadsReducer,
		locationNodeReadsDevicesSearch: locationNodeReadsDevicesSearchReducer,
		watermeter: watermeterReducer,
		watermeterTypesSearch: watermeterTypesSearchReducer,
		watermeterReads: createListSlice('watermeterReads'),
		watermeterRead: watermeterReadReducer,
		watermeterQProfiles: watermeterQProfilesReducer,
		watermeterMonitReads: watermeterMonitReadsReducer,
		watermeterType: watermeterTypeReducer,
		heatallocator: heatallocatorReducer,
		heatAllocatorTypesSearch: heatAllocatorTypesSearchReducer,
		heatallocatorReads: createListSlice('heatallocatorReads'),
		heatallocatorRead: heatallocatorReadReducer,
		wmbusDevices: createListSlice('wmbusDevices'),
		wmbusDevicesInNetwork: createListSlice('wmbusDevicesInNetwork'),
		wmbusDevice: deviceReducer,
		wmbusDeviceTypesSearch: deviceTypesSearchReducer,
		wmbusDeviceType: wmbusDeviceTypeReducer,
		tcpDevices: createListSlice('tcpDevices'),
		tcpDevicesAPT_GSM_NA_1: createListSlice('tcpDevicesAPT_GSM_NA_1'),
		tcpDevicesAPT_GSM_NA_2: createListSlice('tcpDevicesAPT_GSM_NA_2'),
		tcpDevice: tcpDeviceReducer,
		tcpDeviceTypesSearch: tcpDeviceTypesSearchReducer,
		tcpDeviceTypes: tcpDeviceTypesReducer,
		tcpDevicesSearch: tcpDevicesSearchReducer,
		import: importReducer,
		imports: createListSlice('imports'),
		importWizard: importWizardReducer,
		deviceConfigurator: deviceConfiguratorReducer,
		deviceConfigProfiles: deviceConfigProfilesReducer,
		exportsTemplates: createListSlice('exportsTemplates'),
		exportsGenerated: createListSlice('exportsGenerated'),
		stationaryNetworks: createListSlice('stationaryNetworks'),
		exportConfigurator: exportConfiguratorReducer,
		stationaryNetwork: stationaryNetworkReducer,
		schedules: schedulesReducer,
		schedule: scheduleReducer,
		statsLocation: statsLocationReducer,
		billingClient: billingClientReducer,
		billingPeriods: createListSlice('billingPeriods'),
		billingPeriod: billingPeriodReducer,
		billings: createListSlice('billings'),
		billing: billingReducer,
		billingClients: createListSlice('billingClients'),
		converterDiagnostic: createListSlice('converterDiagnostic'),
		deviceTypes: createListSlice('deviceTypes'),
		terminalDiagnosticReads: terminalDiagnosticReadsReducer,
		terminalDiagnosticReadsTimeline: terminalDiagnosticReadsTimelineReducer,
		converterDiagnosticReadsTimeline: converterDiagnosticReadsTimelineReducer,
		report: reportReducer,
		generatedReports: createListSlice('generatedReports'),
		scheduledReports: createListSlice('scheduledReports'),
		activeEvents: createListSlice('activeEvents'),
		pastEvents: createListSlice('pastEvents'),
		deviceEvents: createListSlice('deviceEvents'),
		devicesConfigurations: createListSlice('devicesConfigurations'),
		devicesConfigurationsTypes: devicesConfigurationsTypesReducer,
		currentOrders: createListSlice('currentOrders'),
		heatMeter: heatMeterSlice,
		heatMeterRead: heatMeterReadSlice,
		heatMeterReads: createListSlice('heatMeterReads'),
		order: orderReducer,
		tasks: createListSlice('tasks'),
		task: taskReducer,
		orderStatistics: createListSlice('orderStatistics'),
		usersSelected: createListSlice('usersSelected'),
		usersAll: createListSlice('usersAll'),
		hub: hubSlice,
		hubDiagnostic: hubDiagnosticSlice,
		hubDiagnosticHistory: createListSlice('hubDiagnosticHistory'),
		meshNetworks: createListSlice('meshNetworks'),
		meshNetwork: networkSlice,
		meshTerminal: terminalSlice,
		meshNetworkAvailableItems: createListSlice('meshNetworkAvailableItems'),
		meshNetworkCurrentItems: createListSlice('meshNetworkCurrentItems'),
		meshTerminalTypesSearch: meshTerminalTypesSearchSlice,
		routingTables: createListSlice('routingTables'),
		transmits: createListSlice('transmits'),
		availableLocations: createListSlice('availableLocations'),
		currentLocations: createListSlice('currentLocations'),
		meshNetworkHubItems: createListSlice('meshNetworkHubItems'),
		meshNetworkHubUnknownItems: createListSlice('meshNetworkHubUnknownItems'),
		hubTemperatureProfiles: createListSlice('hubTemperatureProfiles'),
		terminalTransmits: createListSlice('terminalTransmits'),
		currentPointsInOrder: createListSlice('currentPointsInOrder'),
		archivedOrders: createListSlice('archivedOrders'),
		eventsTypes: eventsTypesSlice,
		hubMultiOperations: createListSlice('hubMultiOperations'),
		setWizard: setWizardSlice,
		protocolTemplate: protocolTemplateSlice,
		waterMeterCharts: waterMeterChartsReducer,
		heatAllocatorCharts: heatAllocatorChartsReducer,
		heatMeterCharts: heatMeterChartsSlice,
		sets: setsSlice,
		customers: customersSlice,
		contractors: contractorsSlice,
		ordersPacks: createListSlice('ordersPacks'),
		availableOrders: createListSlice('availableOrders'),
		ordersInPack: createListSlice('ordersInPack'),
		buildingLocals: createListSlice('buildingLocals'),
		buildingPoints: createListSlice('buildingPoints'),
		pointsGroups: createListSlice('pointsGroups'),
		selectedLocationsFilters: createListSlice('selectedLocationsFilters'),
		selectedLocations: createListSlice('selectedLocations'),
		pointsGroupPrimary: createListSlice('pointsGroupPrimary'),
		pointsGroupSecondary: createListSlice('pointsGroupSecondary')
	}
});
