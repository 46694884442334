import { useEffect, useState } from 'react';
import { isEqual } from 'lodash-es';
import useConfigurator from './useConfigurator';
import { StepperBase } from '@sonar-web/common/src/components';

const ConfiguratorStepper = ({ steps }) => {
	const { activeStep, changeStep, isValid, visitedSteps, isEditing } = useConfigurator();

	const [completed, setCompleted] = useState([]);

	const isDisabled = (i) => {
		return !(i === 0 || completed[i - 1]);
	};

	const handleChangeStep = (val) => {
		if (activeStep === val) return;
		if (val > activeStep && isDisabled(val)) return;
		changeStep(val);
	};

	useEffect(() => {
		if (isEqual(isValid, completed)) return;

		if (isEditing) setCompleted(isValid);
		else {
			const newCompleted = isValid.reduce((acc, v, i) => {
				if (v === false) acc[i] = false;
				else acc[i] = visitedSteps[i] ? v : false;

				return acc;
			}, []);

			setCompleted(newCompleted);
		}
	}, [isValid, visitedSteps]);

	return (
		<StepperBase
			steps={steps}
			activeStep={activeStep}
			completed={completed}
			isDisabled={isDisabled}
			handleChangeStep={handleChangeStep}
		/>
	);
};

export default ConfiguratorStepper;
