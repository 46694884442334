import { lazy, Suspense } from 'react';
import { AuthService } from '@sonar/auth';

const View = lazy(() => import(`./Main`));

const App = () => {
	const isAuthorized = AuthService.isLoggedIn();

	if (!isAuthorized) return null;
	return (
		<Suspense fallback=''>
			<View />
		</Suspense>
	);
};

export default App;
