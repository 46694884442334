import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { TextField, CircularProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useLocale, useDebounce } from '../../hooks';

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		fontSize: theme.typography.body2.fontSize,
		'& input': {
			fontSize: theme.typography.body2.fontSize
		}
	},
	loading: { padding: 0 },
	option: {
		paddingTop: 4,
		paddingBottom: 4,
		'& span': {
			fontWeight: theme.typography.fontWeightLight
		}
	},
	highlight: {
		fontWeight: `${theme.typography.fontWeightMedium} !important`,
		color: theme.palette.primary.main
	}
}));

const SearchAutocomplete = ({
	label,
	options,
	loading,
	hasMore,
	handleOnChange,
	displayValue,
	translateDisplayValue,
	searchData,
	ownInput,
	inputName,
	setInputRef,
	onListClose,
	error,
	helperText,
	customRenderOption,
	translateDisplayValueConnector = '_',
	additionalInputProps = {}
}) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const [open, setOpen] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const debounceSearchTerm = useDebounce(inputValue, 300);

	const handleOpen = (state) => {
		setOpen(state);
		if (!state && onListClose) onListClose();
	};

	const handleOnInputChange = (_, value) => setInputValue(value);

	const resetInputCallback = () => setInputValue('');

	useEffect(() => {
		if (open) searchData(inputValue);
	}, [debounceSearchTerm, open]);

	return (
		<Autocomplete
			className='searchAutocomplete'
			open={open}
			value={null}
			onOpen={() => handleOpen(true)}
			onClose={() => handleOpen(false)}
			inputValue={inputValue}
			onInputChange={handleOnInputChange}
			onChange={(event, value) => handleOnChange(event, value, resetInputCallback)}
			isOptionEqualToValue={(option, value) => option[displayValue] === value[displayValue]}
			getOptionLabel={(option) => option[displayValue]}
			filterOptions={(opts, params) => {
				if (!ownInput) return opts;

				let filtered = filter(opts, params);

				if (params.inputValue !== '') {
					filtered = [
						{
							inputValue: params.inputValue,
							[displayValue]: `${params.inputValue}`
						}
					].concat(filtered);
				}

				return filtered;
			}}
			options={options}
			loading={true}
			loadingText={null}
			size='small'
			noOptionsText={translate('Common_NoResults')}
			autoHighlight
			openOnFocus
			blurOnSelect
			clearOnBlur
			clearOnEscape
			includeInputInList
			disableClearable
			classes={{ root: classes.root, loading: classes.loading, option: classes.option }}
			ListboxProps={{
				'data-at': 'AutocompleteListbox',
				onScroll: (event) => {
					const listboxNode = event.currentTarget;

					if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
						if (hasMore) searchData(inputValue, true);
					}
				}
			}}
			clearIcon={<CloseIcon fontSize='small' />}
			renderInput={(params) => (
				<TextField
					{...params}
					inputRef={(input) => {
						if (setInputRef) setInputRef(input);
					}}
					name={inputName ?? null}
					label={translate(label)}
					fullWidth
					error={error}
					helperText={translate(helperText)}
					FormHelperTextProps={{
						'data-at-error': helperText
					}}
					InputProps={{
						...params.InputProps,
						readOnly: translateDisplayValue != null,
						endAdornment: (
							<>
								{loading ? <CircularProgress color='primary' size={20} /> : null}
								{params.InputProps.endAdornment}
							</>
						)
					}}
					{...additionalInputProps}
				/>
			)}
			renderOption={(props, option) => {
				const optionValue =
					typeof translateDisplayValue === 'string'
						? translate(`${translateDisplayValue}${translateDisplayValueConnector}${option[displayValue]}`)
						: option[displayValue];

				const matches = match(optionValue, inputValue);
				const parts = parse(optionValue, matches).map((part) => {
					part.text = part.text?.replaceAll(' ', '\u00a0');
					return part;
				});

				return customRenderOption ? (
					customRenderOption(option, parts, classes, props)
				) : (
					<li {...props} key={props.id}>
						{parts.map((part, index) => (
							<Typography
								data-at={option[displayValue]}
								variant='body2'
								component='span'
								key={index}
								className={clsx({ [classes.highlight]: part.highlight })}>
								{part.text}
							</Typography>
						))}
					</li>
				);
			}}
		/>
	);
};

export default SearchAutocomplete;
