import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setObjectSucessfullyDeleted } from '@sonar-web/common/src/appSlice';

export default () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const showDeleteSuccessPage = (obj) => {
		dispatch(setObjectSucessfullyDeleted(obj));
		history.replace('/deletesuccess');
	};

	return {
		showDeleteSuccessPage
	};
};
