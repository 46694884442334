import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useLocale } from '../../../../hooks';

const ConfiguratorTextfield = ({
	onChange,
	onBlur,
	name,
	value,
	error,
	helperText,
	dataAtError,
	form,
	element,
	group,
	forceUpdateValidationSchema
}) => {
	const { translate } = useLocale();
	const { dependsOn, defaultValue, isReadOnly } = element;
	const isDependent = dependsOn !== null;
	const dependencyValue = isDependent ? dependsOn.value == form.values[dependsOn.key] : false;

	const [dependencyVisibility, setDependencyVisibility] = useState(dependencyValue);

	useEffect(() => {
		if (!dependsOn) return;

		setDependencyVisibility(dependencyValue);
		if (group.included) forceUpdateValidationSchema(form.values);
	}, [form.values[dependsOn?.key]]);

	useEffect(() => {
		if (!dependsOn) return;
		if (!dependencyVisibility) form.setFieldValue(name, defaultValue);
	}, [dependencyVisibility]);

	if (dependsOn && !dependencyVisibility) return null;
	return (
		<TextField
			type='text'
			margin='normal'
			size='small'
			fullWidth
			disabled={Boolean(isReadOnly)}
			label={translate(name)}
			name={name}
			value={value ?? ''}
			onChange={onChange}
			onBlur={onBlur}
			error={error}
			helperText={helperText}
			FormHelperTextProps={{
				'data-at-error': dataAtError
			}}
		/>
	);
};

export default ConfiguratorTextfield;
