import { lazy } from 'react';

const Empty = lazy(() => import('./features/Empty/Empty'));

export default [
	{
		component: Empty,
		path: '/empty',
		exact: true,
		name: '',
		standalone: true
	}
];
