import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import background from './blurredBg.png';
import PadlockIcon from '@sonar-web/common/src/icons/PadlockIcon';
import { useLocale } from '@sonar-web/common/src/hooks';
import BaseButton from '@sonar-web/common/src/components/Buttons/BaseButton';
import { AuthService } from '@sonar/auth';
import useCurrentTenant from '../Hooks/useCurrentTenant';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		background: `url(${background}) no-repeat top center fixed`,
		backgroundSize: 'cover',
		minHeight: '100%',
		minWidth: '100%',
		height: 'auto',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	},
	card: {
		display: 'flex',
		background: theme.palette.common.white,
		border: '0px solid transparent',
		borderRadius: 7,
		maxWidth: 'clamp(300px, 90vw, 700px)'
	},
	left: {
		background: 'linear-gradient(#084B92, #052B54)',
		borderRadius: '7px 0 0 7px',
		minWidth: 150,
		color: theme.palette.common.white,
		position: 'relative'
	},
	leftIcon: {
		position: 'absolute',
		bottom: -18,
		left: -27
	},
	right: {
		padding: theme.spacing(5)
	},
	header: {
		fontWeight: theme.typography.fontWeightMedium
	},
	text: {
		marginTop: theme.spacing(1)
	},
	text2: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(3)
	}
}));

const TimeLicenseExpired = ({ children }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const { getCurrentTenant } = useCurrentTenant();
	const currentTenant = getCurrentTenant();

	const handleClick = () => AuthService.doLogout();

	if (currentTenant.isValid) return children;

	return (
		<Box className={classes.wrapper}>
			<Box className={classes.card}>
				<Box className={classes.left}>
					<Box className={classes.leftIcon}>
						<PadlockIcon fontSize='xxxl' />
					</Box>
				</Box>
				<Box className={classes.right}>
					<Typography variant='h5' classes={{ root: classes.header }}>
						{translate('Common_InTheEnd')}
					</Typography>
					<Typography variant='body2' color='textSecondary' classes={{ root: classes.text }}>
						{translate('Common_InTheEndDescription')}
					</Typography>
					<Typography variant='body2' classes={{ root: classes.text2 }}>
						{translate('Common_DistributorContact')}
					</Typography>
					<BaseButton onClick={handleClick}>Access_Logout</BaseButton>
				</Box>
			</Box>
		</Box>
	);
};

export default TimeLicenseExpired;
