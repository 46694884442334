import Keycloak from 'keycloak-js';
import { AUTHORIZATION_DISABLED } from '@sonar-web/common/src/constants/system';
import LocalStorageHelper from '@sonar-web/common/src/helpers/LocalStorageHelper';

const keycloakRoles = ['offline_access', 'uma_authorization', 'default-roles-sonar-web'];
let _kc = {};

const initKeycloak = (config, onAuthenticatedCallback) => {
	_kc = new Keycloak(config);
	_kc.onTokenExpired = () => console.log('TokenExpired!');
	_kc.init({ onLoad: 'login-required', checkLoginIframe: false }).then(() => {
		onAuthenticatedCallback();
	});
};

const doLogin = _kc.login;

const doLogout = () => _kc.logout({ redirectUri: window.location.origin });

const getToken = () => _kc.token;

const clearToken = () => _kc.clearToken();

const getTokenParsed = () => _kc.tokenParsed;

const getLocales = () => (AUTHORIZATION_DISABLED ? 'pl' : getTokenParsed().locale);

const isLoggedIn = () => (AUTHORIZATION_DISABLED ? true : !!_kc.token);

const updateToken = async () => {
	// console.log(_kc);
	try {
		if (!_kc.isTokenExpired()) return await _kc.updateToken();

		const refreshToken = _kc.refreshTokenParsed;
		if (!refreshToken || new Date().getTime() > refreshToken.exp * 1000) {
			console.log({ refreshTokenExpiryDate: new Date(refreshToken.exp * 1000) });
			doLogout();
			throw new Error('TokenRefreshFailed');
		}

		return await _kc.updateToken();
	} catch (err) {
		doLogout();
		throw new Error('TokenRefreshFailed');
	}
};

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const getClientId = () => _kc.clientId;

const getCurrentUser = () => _kc.idTokenParsed;

const getKeycloakObject = () => _kc;

const getTenants = () => {
	const groups = getTokenParsed().tenants;

	if (!groups || groups.length === 0) return [];

	return groups.map((group) => {
		return { id: group.id, name: group.name };
	});
};

const getRoles = () => {
	const token = getTokenParsed();

	if (!token) return [];
	const roles = token.realm_access.roles;

	if (!roles || roles.length === 0) return [];

	return roles.filter((role) => !keycloakRoles.includes(role));
};

const sessionChanged = () => {
	const session = LocalStorageHelper.getValue('spidap_kcSession');
	return session !== _kc.tokenParsed.session_state;
};

const updateSessionState = () => {
	LocalStorageHelper.setValue('spidap_kcSession', _kc.tokenParsed.session_state);
};

const AuthService = {
	initKeycloak,
	doLogin,
	doLogout,
	isLoggedIn,
	getToken,
	getTokenParsed,
	getLocales,
	updateToken,
	getUsername,
	hasRole,
	getClientId,
	getCurrentUser,
	clearToken,
	getKeycloakObject,
	getTenants,
	getRoles,
	sessionChanged,
	updateSessionState
};

export default AuthService;
