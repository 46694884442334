import React, { useState, useEffect } from 'react';
import { IconButton, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { useLocale, useDebounce, useIsMount } from '../../hooks';
import { filterOperators } from '../../constants';
import BaseTooltip from '../BaseTooltip/BaseTooltip';
import { PartialMatchIcon, ExactMatchIcon } from '../../icons';

const useStyles = makeStyles(() => ({
	adornmentIcon: {
		padding: 0,
		marginRight: '-2px'
	},
	exactMatchRoot: {
		minWidth: 30
	}
}));

const FiltersControlTextSingle = ({ member, label, stateSlice, onChange, onClearMemberAll }) => {
	const classes = useStyles();
	const isMount = useIsMount();
	const { translate } = useLocale();
	const [value, setValue] = useState('');
	const [exactMatch, setExactMatch] = useState(false);
	const debounceSearchTerm = useDebounce(value);

	const handleChange = (event) => setValue(event.target.value);

	const handleToggleExactMatch = () => setExactMatch(!exactMatch);

	const handlerClearValue = () => setValue('');

	const handleClearFilter = () => onClearMemberAll(member.name);

	const handleSetFilter = () => {
		onChange(
			[
				{
					member,
					value: debounceSearchTerm,
					operator: exactMatch ? filterOperators.equal : filterOperators.like
				}
			],
			member.name
		);
	};

	useEffect(() => {
		if (isMount) return;
		if (value === '') {
			handleClearFilter();
			return;
		}
		handleSetFilter();
	}, [debounceSearchTerm, exactMatch]);

	useEffect(() => {
		if (stateSlice == null || (stateSlice && Object.keys(stateSlice).length === 0) || !stateSlice[member.name]) {
			handlerClearValue();
		}
	}, [stateSlice]);

	return (
		<TextField
			name={member.name}
			label={translate(label)}
			type='text'
			size='small'
			fullWidth
			classes={{ root: classes.root }}
			value={value}
			onChange={handleChange}
			InputProps={{
				margin: 'none',
				endAdornment: (
					<>
						{value !== '' && (
							<IconButton
								name={`${member.name}Clear`}
								size='small'
								className={classes.adornmentIcon}
								aria-label='filter_text_clear'
								onClick={handlerClearValue}>
								<CloseIcon fontSize='small' />
							</IconButton>
						)}
						<BaseTooltip text={exactMatch ? 'Common_ExactMatch' : 'Common_PartialMatch'}>
							<IconButton
								name={exactMatch ? `${member.name}ExactMatch` : `${member.name}PartialMatch`}
								variant='text'
								color={exactMatch ? 'primary' : 'default'}
								size='small'
								classes={{ root: classes.exactMatchRoot }}
								className={classes.adornmentIcon}
								aria-label='filter_word_match'
								onClick={handleToggleExactMatch}>
								{exactMatch ? <ExactMatchIcon /> : <PartialMatchIcon />}
							</IconButton>
						</BaseTooltip>
					</>
				)
			}}
		/>
	);
};

export default FiltersControlTextSingle;
