import { useMemo } from 'react';
import { useLocale } from '@sonar-web/common/src/hooks';
import { DateRangeOptions } from '@sonar-web/common/src/features/DateRange/DateRangeOptions';
import { formatDateTime } from '@sonar-web/common';
import useStatistics from './useStatistics';
import StatisticsSettingsItem from './StatisticsSettingsItem';
import DateRangeIcon from '../Icons/DateRangeIcon';

const StatisticsSettingsDate = () => {
	const { translate } = useLocale();
	const { dateRange } = useStatistics();

	const text = useMemo(() => {
		const option = Object.values(DateRangeOptions).find((dro) => dro.value === dateRange.dateRangeOption);
		const date = formatDateTime(dateRange.dateRangeDate);

		return `${translate(option.description)} (${date} - ${translate('Now')})`;
	}, [dateRange.dateRangeDate, dateRange.dateRangeOption]);

	const rangeOption =
		dateRange.dateRangeOption == 0
			? `fromDate_${formatDateTime(dateRange.dateRangeDate, 'date')}`
			: `lastDays${dateRange.dateRangeOption}`;

	return <StatisticsSettingsItem Icon={DateRangeIcon} text={text} dataat={`dataRangeOption|${rangeOption}`} />;
};

export default StatisticsSettingsDate;
