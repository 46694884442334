import { lazy } from 'react';
import { ROUTE_GUID } from '@sonar-web/common';
import { Features } from '@sonar/auth';

const HeatallocatorPage = lazy(() => import('./Heatallocator/HeatallocatorPage'));

export default [
	{
		component: HeatallocatorPage,
		feature: Features.HeatallocatorPage.name,
		path: `/heatallocators/:id(${ROUTE_GUID})`,
		exact: true,
		name: '{0}'
	}
];
