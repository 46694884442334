import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { Box, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { formatDateTimeSecondsPrecision } from '@sonar-web/common';
import { usePrevious } from '@sonar-web/common/src/hooks';
import TimelinePoint from './TimelinePoint';
import TimelinePointTooltip from './TimelinePointTooltip';
import { formatDate, addHours, groupReadsByPeriod } from './helpers';
import TimelineBottomIndicator from './TimelineBottomIndicator';
import { dotSize, marginLeft, marginRight } from './constants';

const useStyles = makeStyles((theme) => ({
	timelinePeriod: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		flex: 1,
		minHeight: 28
	},
	secondaryText: {
		padding: 0,
		margin: '0 2px',
		color: theme.palette.text.secondary
	},
	timelineConnector: {
		position: 'absolute',
		left: 0,
		top: 6,
		width: '100%',
		height: 2,
		backgroundColor: theme.palette.grey[300]
	}
}));

const groupReadsBy6Hours = (readsItems, range) => {
	const startMs = range.start.getTime();
	const endMs = range.end.getTime();

	const now = new Date();
	const hoursPeriodInMs = addHours(now, 6).getTime() - now.getTime();
	const groups = groupReadsByPeriod(readsItems, range, hoursPeriodInMs).reduce((result, g) => {
		if (g.reads.length == 0) return result;

		let precent = null;

		if (g.reads.length == 1) {
			const read = g.reads[0];

			precent = Math.round(((read.date.getTime() - startMs) / (endMs - startMs)) * 100);
		} else {
			precent = Math.round(
				(((g.range.end.getTime() + g.range.start.getTime()) / 2 - startMs) / (endMs - startMs)) * 100
			);
		}

		result.push({
			...g,
			position: {
				precent,
				offset: 0
			}
		});

		return result;
	}, []);

	return groups;
};

const TimelineOneDayPeriod = ({ reads, range, ...rest }) => {
	const classes = useStyles();
	const [readGroups, setReadGroups] = useState([]);
	const previousReads = usePrevious(reads);

	const theme = useTheme();

	useEffect(() => {
		const equalReads = isEqual(reads, previousReads);
		if (equalReads) return;

		const groups = groupReadsBy6Hours(reads, range);

		setReadGroups(groups);
	}, [reads]);

	return (
		<Box display='flex' flexDirection='column' flex={1}>
			<Box className={classes.timelinePeriod}>
				{readGroups && (
					<Box style={{ position: 'relative', width: '100%' }}>
						<Box className={classes.timelineConnector} {...rest}></Box>
						{readGroups?.map((group, index) => {
							const precent = group.position.precent;
							const offset = group.position.offset;

							if (group.reads.length == 1) {
								const read = group.reads[0];

								const tooltipText = formatDateTimeSecondsPrecision(read.date);

								return (
									<Box
										key={index}
										style={{
											position: 'absolute',
											background: theme.palette.common.white,
											padding: '0 3px 0 3px',
											top: 3,
											left: `calc((100% - ${marginLeft + marginRight}px) * ${
												precent / 100
											} + ${marginLeft}px + ${offset}px)`
										}}>
										<TimelinePointTooltip text={tooltipText}>
											<TimelinePoint size={dotSize} color={theme.palette.primary.main} />
										</TimelinePointTooltip>
									</Box>
								);
							} else {
								const tooltipText = `${formatDateTimeSecondsPrecision(
									group.reads[0].date
								)} - ${formatDateTimeSecondsPrecision(group.reads[group.reads.length - 1].date)}`;

								return (
									<Box
										key={index}
										style={{
											position: 'absolute',
											background: theme.palette.common.white,
											padding: '0 3px 0 3px',
											left: `calc((100% - ${marginLeft + marginRight}px) * ${
												precent / 100
											} + ${marginLeft}px + ${offset}px)`
										}}>
										<TimelinePointTooltip text={tooltipText}>
											<TimelinePoint
												size={dotSize}
												color={theme.palette.primary.main}
												text={`${group.reads.length}x`}
											/>
										</TimelinePointTooltip>
									</Box>
								);
							}
						})}
					</Box>
				)}
			</Box>
			<TimelineBottomIndicator className={classes.timelineTopIndicator} minWidth={200}>
				<Typography
					variant='caption'
					classes={{
						root: classes.secondaryText
					}}>
					{formatDate(range.start)}
				</Typography>
			</TimelineBottomIndicator>
		</Box>
	);
};

export default TimelineOneDayPeriod;
