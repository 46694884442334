import clsx from 'clsx';
import styled from '@emotion/styled';
import { Typography, Box } from '@mui/material';
import { useLocale } from '../../hooks';
import EmptyListIcon from '../../icons/EmptyListIcon';
import BaseLinearProgress from '../BaseLinearProgress/BaseLinearProgress';

const Wrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	minHeight: '300px',
	'&.emptyPlaceholder__bordered': {
		borderWidth: '1px',
		borderStyle: 'dashed',
		borderColor: theme.palette.border.light,
		borderRadius: theme.shape.borderRadius
	},
	'&.emptyPlaceholder__fullHeight': {
		height: '100%'
	}
}));

const EmptyPlaceholder = ({ children, text, icon = true, bordered = true, fullHeight = true, pending, ...props }) => {
	const { translate } = useLocale();
	const disableIcon = icon === false;
	const Icon = typeof icon === 'function' ? icon : EmptyListIcon;

	return (
		<Wrapper
			className={clsx('emptyPlaceholder', {
				emptyPlaceholder__bordered: bordered,
				emptyPlaceholder__fullHeight: fullHeight
			})}
			{...props}>
			{!disableIcon && <Icon className='emptyPlaceholder_icon' color='disabled' sx={{ fontSize: '4rem' }} />}
			{text && (
				<Typography
					className='emptyPlaceholder_text'
					variant='body2'
					align='center'
					whiteSpace='break-spaces'
					color='GrayText'
					mt='1rem'>
					{pending ? (
						<BaseLinearProgress pending={true} sx={{ mt: '10px', width: '200px' }} />
					) : (
						translate(text)
					)}
				</Typography>
			)}
			{children}
		</Wrapper>
	);
};

export default EmptyPlaceholder;
