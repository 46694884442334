import { ROUTE_GUID } from '@sonar-web/common';

export const moduleName = 'mesh';

export const slices = {
	hub: 'hub',
	meshNetworks: 'meshNetworks',
	meshNetwork: 'meshNetwork',
	meshTerminal: 'meshTerminal',
	meshNetworkAvailableItems: 'meshNetworkAvailableItems',
	meshNetworkCurrentItems: 'meshNetworkCurrentItems',
	meshNetworkHubItems: 'meshNetworkHubItems',
	meshNetworkHubUnknownItems: 'meshNetworkHubUnknownItems',
	meshTerminalTypesSearch: 'meshTerminalTypesSearch',
	routingTables: 'routingTables',
	transmits: 'transmits',
	hubTemperatureProfiles: 'hubTemperatureProfiles',
	terminalTransmits: 'terminalTransmits',
	hubMultiOperations: 'hubMultiOperations'
};

export const routes = {
	hubNew: () => '/hubs',
	hubDetails: (props) => (props ? `/hubs/${props.hubId}` : `/hubs/:hubId(${ROUTE_GUID})`),
	hubEdit: (props) => (props ? `/hubs/${props.hubId}/edit` : `/hubs/:hubId(${ROUTE_GUID})/edit`),
	hubDelete: (props) => (props ? `/hubs/${props.hubId}/delete` : `/hubs/:hubId(${ROUTE_GUID})/delete`),
	hubConfigurationNew: (props) =>
		props ? `/hubs/${props.hubId}/configuration` : `/hubs/:hubId(${ROUTE_GUID})/configuration`,
	hubConfigurationNewProfile: (props) =>
		props
			? `/hubs/${props.hubId}/configuration?type=profiles`
			: `/hubs/:hubId(${ROUTE_GUID})/configuration?type=profiles`,
	hubConfigurationEdit: (props) =>
		props
			? `/hubs/${props.hubId}/configuration/edit?pending=${props.pending === true}`
			: `/hubs/:hubId(${ROUTE_GUID})/configuration/edit`,
	meshNetworkHubAssign: (props) =>
		props ? `/meshNetworks/${props.networkId}/hub` : `/meshNetworks/:networkId(${ROUTE_GUID})/hub`,
	meshNetworkHubReplace: (props) =>
		props
			? `/meshNetworks/${props.networkId}/hub/${props.hubId}`
			: `/meshNetworks/:networkId(${ROUTE_GUID})/hub/:hubId(${ROUTE_GUID})`,
	meshNetworks: () => '/meshNetworks',
	meshNetworkAdd: () => '/meshNetworks/add',
	meshNetworkDetails: (props) =>
		props ? `/meshNetworks/${props.networkId}` : `/meshNetworks/:networkId(${ROUTE_GUID})`,
	meshNetworkStructure: (props) =>
		props ? `/meshNetworks/${props.networkId}/structure` : `/meshNetworks/:networkId(${ROUTE_GUID})/structure`,
	meshNetworkSchedule: (props) =>
		props ? `/meshNetworks/${props.networkId}/schedule` : `/meshNetworks/:networkId(${ROUTE_GUID})/schedule`,
	meshNetworkScheduleEdit: (props) =>
		props
			? `/meshNetworks/${props.networkId}/schedule/${props.scheduleId}`
			: `/meshNetworks/:networkId(${ROUTE_GUID})/schedule/:scheduleId(${ROUTE_GUID})`,
	meshNetworkEdit: (props) =>
		props ? `/meshNetworks/${props.networkId}/edit` : `/meshNetworks/:networkId(${ROUTE_GUID})/edit`,
	meshNetworkDelete: (props) =>
		props ? `/meshNetworks/${props.networkId}/delete` : `/meshNetworks/:networkId(${ROUTE_GUID})/delete`,
	terminalDetails: (props) =>
		props ? `/meshterminals/${props.terminalId}` : `/meshterminals/:terminalId(${ROUTE_GUID})`,
	terminalEdit: (props) =>
		props ? `/meshterminals/${props.terminalId}/edit` : `/meshterminals/:terminalId(${ROUTE_GUID})/edit`,
	terminalDelete: (props) =>
		props ? `/meshterminals/${props.terminalId}/delete` : `/meshterminals/:terminalId(${ROUTE_GUID})/delete`,
	routingTables: (props) =>
		props
			? `/meshNetworks/${props.networkId}/routingTables`
			: `/meshNetworks/:networkId(${ROUTE_GUID})/routingTables`,
	routingTablesVisualization: (props) =>
		props
			? `/meshNetworks/${props.networkId}/routingTables/${props.routingTableId}/visualization`
			: `/meshNetworks/:networkId(${ROUTE_GUID})/routingTables/:routingTableId(${ROUTE_GUID})/visualization`,
	transmits: (props) =>
		props ? `/meshNetworks/${props.networkId}/transmits` : `/meshNetworks/:networkId(${ROUTE_GUID})/transmits`,
	transmitNew: (props) =>
		props
			? `/meshNetworks/${props.networkId}/transmit/${props.transmitType}`
			: `/meshNetworks/:networkId(${ROUTE_GUID})/transmit/:transmitType`,
	transmitEdit: (props) =>
		props
			? `/meshNetworks/${props.networkId}/transmit/${props.transmitType}/${props.transmitId}/edit`
			: `/meshNetworks/:networkId(${ROUTE_GUID})/transmit/:transmitType/:transmitId(${ROUTE_GUID})/edit`,
	transmitPreview: (props) =>
		props
			? `/meshNetworks/${props.networkId}/transmit/${props.transmitType}/${props.transmitId}/preview`
			: `/meshNetworks/:networkId(${ROUTE_GUID})/transmit/:transmitType/:transmitId(${ROUTE_GUID})/preview`
};

const Module = {
	moduleName,
	slices,
	routes
};

export default Module;
