export const modules = {
	tcp: {
		name: 'tcp',
		devicesRoute: 'tcp'
	},
	wmbus: {
		name: 'wmbus',
		devicesRoute: 'wmbusdevices'
	},
	watermeter: {
		name: 'watermeter',
		devicesRoute: 'watermeters'
	},
	heatallocator: {
		name: 'heatallocator',
		devicesRoute: 'heatallocators'
	},
	heatmeter: {
		name: 'heatmeter',
		devicesRoute: 'heatmeter'
	},
	mesh: {
		name: 'mesh',
		devicesRoute: 'meshterminals'
	},
	meshterminal: {
		name: 'meshterminal',
		devicesRoute: 'meshterminals'
	},
	meshhub: {
		name: 'hubs',
		devicesRoute: 'hubs'
	},
	other: {
		name: 'other',
		devicesRoute: null
	}
};

export default () => {
	const getModuleDeviceRouteByModuleName = (name) => {
		return modules[name] ?? modules.other;
	};

	return { getModuleDeviceRouteByModuleName, modules };
};
