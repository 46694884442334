import { createSlice } from '@reduxjs/toolkit';
import { pageDescriptor, getSliceFilters } from '@sonar-web/common';
import { fetchRoles } from './rolesApi';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import AuthService from '@sonar/auth/src/AuthService';

const slice = 'rolesSearch';
const getClient = () => AuthService.getClientId();

export const rolesSearchSlice = createSlice({
	name: slice,
	initialState: {
		pageDescriptor,
		fetchPending: false,
		roles: [],
		pending: false,
		totalCount: 0,
		hasMore: true,
		reload: false,
		reset: true
	},
	reducers: {
		fetchRolesSuccess: (state, action) => {
			const payload = [...action.payload.elements];

			state.roles = state.reset ? payload : state.roles.concat(payload);
			state.totalCount = action.payload.totalCount;
			state.fetchPending = false;
		},
		fetchRolesPending: (state) => {
			state.fetchPending = true;
		},
		setHasMore: (state) => {
			state.hasMore = state.totalCount > state.pageDescriptor.Offset + state.pageDescriptor.Limit;
		},
		setResetAndReload: (state) => {
			state.roles = [];
			state.reload = true;
			state.reset = true;
			state.hasMore = true;
			state.pageDescriptor.Offset = 0;
			state.pageDescriptor.FilterDescriptors = [];
			state.pageDescriptor.SortDescriptors = [];
		},
		setResetToFirstPage: (state) => {
			state.roles = [];
			state.reload = true;
			state.reset = true;
			state.hasMore = true;
			state.pageDescriptor.Offset = 0;
		},
		setAppend: (state, action) => {
			state.reset = !action.payload;
			if (action.payload) state.pageDescriptor.Offset += state.pageDescriptor.Limit;
			else state.pageDescriptor.Offset = 0;
		}
	}
});

export const { fetchRolesSuccess, fetchRolesPending, setHasMore, setResetAndReload, setResetToFirstPage, setAppend } =
	rolesSearchSlice.actions;

export const fetchRolesAsync = () => async (dispatch, getState) => {
	let response;

	try {
		dispatch(fetchRolesPending());

		let pd = Object.assign({}, getState().rolesSearch.pageDescriptor);
		let searchFilters = getSliceFilters(getState(), slice);

		pd.FilterDescriptors = searchFilters;

		response = await fetchRoles(getClient(), pd);
	} catch (error) {
		dispatch(addErrorAsync({ slice, error }));
		return;
	}

	dispatch(fetchRolesSuccess(response));
	dispatch(setHasMore());
};

export const selectRoles = (state) => {
	return {
		dataRows: state.rolesSearch.roles,
		pending: state.rolesSearch.pending,
		hasMore: state.rolesSearch.hasMore
	};
};

export default rolesSearchSlice.reducer;
