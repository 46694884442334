import { lazy } from 'react';
import { ROUTE_GUID } from '@sonar-web/common';
import { Features } from '@sonar/auth';

const LocationNodePageDashboardLoader = lazy(() => import('./LocationNode/Dashboard/LocationNodePageDashboardLoader'));

export default [
	{
		component: LocationNodePageDashboardLoader,
		feature: Features.LocationNodePage.name,
		path: `/locationnodes/:id(${ROUTE_GUID})`,
		exact: true,
		name: '{0}'
	}
];
