import LinkRouter from '@sonar-web/common/src/components/Router/LinkRouter';
import { useRoutes } from '@sonar-web/common/src/hooks';

const PointSetsDevice = ({ device }) => {
	const { getModuleDeviceRouteByModuleName } = useRoutes();
	const route = getModuleDeviceRouteByModuleName(device.sourceService);

	return (
		<LinkRouter to={`/${route}/${device.id}`}>
			<strong>{device.number}</strong> {`(${device.typeName})`}
		</LinkRouter>
	);
};

export default PointSetsDevice;
