import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Box } from '@mui/material';
import ConfirmationView from '@sonar-web/common/src/components/Confirmation/ConfirmationView';
import ReportSubmitName from './ReportSubmitName';
import {
	addOrEditAsync,
	addOrEditTemplateAsync,
	selectAddOrEdit,
	selectAddOrEditTemplate
} from '../../configuratorSlice';
import ReportSubmitSummary from './ReportSubmitSummary';
import SaveSettingsType from '../SaveSettings/SaveSettingsType';

const ReportSubmit = ({ useConfigurator, configuratorData, updateConfiguratorData, property, props }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { isEditing } = useConfigurator();
	const { addOrEditPending, addOrEditSuccess } = useSelector(selectAddOrEdit);
	const { addOrEditTemplatePending } = useSelector(selectAddOrEditTemplate);

	const hasSchedule = configuratorData.cronTime?.length > 0;

	const handleSubmit = (e) => {
		e.preventDefault();

		const data = clearData(configuratorData);

		const submitData = {
			...data,
			reportSettings: JSON.stringify(configuratorData.reportSettings)
		};

		if (configuratorData.addTemplate === SaveSettingsType.DontSave.value) {
			dispatch(addOrEditAsync(isEditing, props, submitData));
			return;
		}

		dispatch(
			addOrEditTemplateAsync(
				configuratorData.addTemplate === SaveSettingsType.SaveInCurrentTemplate.value,
				props,
				submitData
			)
		).then((err) => {
			if (err) return;
			dispatch(addOrEditAsync(isEditing, props, submitData));
		});
	};

	useEffect(() => {
		if (!addOrEditSuccess) return;
		history.push(hasSchedule ? '/scheduledReports' : '/generatedReports');
	}, [addOrEditSuccess]);

	return (
		<form onSubmit={(e) => handleSubmit(e)}>
			<ConfirmationView
				onConfirm={handleSubmit}
				confirmText='Common_ConfirmReport'
				pending={addOrEditTemplatePending || addOrEditPending}
				center={false}
				disableGutter
				initialDisable={!isEditing}>
				{({ setDisabled, disabled }) => (
					<Box maxWidth={500}>
						<ReportSubmitSummary configuratorData={configuratorData} />
						<ReportSubmitName
							configuratorData={configuratorData}
							updateConfiguratorData={updateConfiguratorData}
							property={property}
							setDisabled={setDisabled}
							disabled={disabled}
						/>
					</Box>
				)}
			</ConfirmationView>
		</form>
	);
};

export default ReportSubmit;

function clearData(d) {
	let data = { ...d };

	delete data.workflow;
	delete data.addTemplate;
	delete data.recipients;

	return data;
}
