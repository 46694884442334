import React from 'react';
import clsx from 'clsx';
import { Typography, ListItemAvatar, Avatar, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ListItemTextBase, PanelListItem, formatDateTimeSecondsPrecision } from '@sonar-web/common';
import { useLocale } from '@sonar-web/common/src/hooks';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ConverterStatus from '../ConverterStatus';

const useStyles = makeStyles((theme) => ({
	primaryText: {
		fontWeight: theme.typography.fontWeightMedium
	},
	secondaryText: {
		color: theme.palette.text.secondary
	},
	listItemAvatarRoot: {
		alignSelf: 'flex-start',
		marginTop: 6
	},
	avatarRoot: {
		color: theme.palette.text.secondary
	},
	dotIconReadOk: {
		color: theme.palette.success.main
	},
	dotIconReadNotOk: {
		color: theme.palette.error.main
	}
}));

const ConverterDiagnosticListItem = ({ row, selected }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const [isExpanded, setIsExpanded] = React.useState(false);
	const success = row.status === ConverterStatus.Connected.name;
	const data = row.diagnosticData == null || row.diagnosticData === '' ? null : JSON.parse(row.diagnosticData);

	const handleClick = () => setIsExpanded(!isExpanded);

	return (
		<PanelListItem
			row={row}
			onClick={handleClick}
			selected={selected}
			renderFrontItem={() => {
				return (
					<>
						<ListItemAvatar classes={{ root: classes.listItemAvatarRoot }}>
							<Avatar classes={{ root: classes.avatarRoot }}>
								<FiberManualRecordIcon
									classes={{
										root: clsx({
											[classes.dotIconReadOk]: success,
											[classes.dotIconReadNotOk]: !success
										})
									}}
								/>
							</Avatar>
						</ListItemAvatar>
						<ListItemTextBase disableTypography={true} hideBorder={true}>
							<Box display='flex' flexDirection='column'>
								<Box justifyContent='flex-start' flexGrow={1} flexDirection='row'>
									<Typography
										variant='body2'
										classes={{
											root: classes.primaryText
										}}>
										{`${formatDateTimeSecondsPrecision(row.date)} ${translate(
											data == null
												? 'Stationary_LegendConverterDisconnected'
												: 'Stationary_LegendConverterConnected'
										)}
`}
									</Typography>
									{data == null ? (
										<Typography variant='body2' color='textSecondary'>
											{translate('Stationary_ConverterNoDiagnosticDataText')}
										</Typography>
									) : (
										<Typography
											component='span'
											variant='body2'
											color='textSecondary'
											sx={{
												'& > span:after': {
													content: '" | "'
												},
												'& > span:last-child:after': {
													content: '""'
												}
											}}>
											{data.Lac != null && (
												<span>
													{translate('Lac')} {data.Lac}
												</span>
											)}
											{data.Cid != null && (
												<span>
													{translate('Cid')} {data.Cid}
												</span>
											)}
											{data.Rssi != null && (
												<span>
													{translate('Rssi')} {data.Rssi} {translate('Dbm')}
												</span>
											)}
											{data.Ip != null && (
												<span>
													{translate('Ip')} {data.Ip}
												</span>
											)}
											{data.MacAddress != null && (
												<span>
													{translate('MacAddress')} {data.MacAddress}
												</span>
											)}
										</Typography>
									)}
								</Box>
							</Box>
						</ListItemTextBase>
					</>
				);
			}}
		/>
	);
};

export default ConverterDiagnosticListItem;
