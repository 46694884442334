import {
	fetchLocationStatsAsync,
	setResetAndReload,
	setSelected,
	setOffset,
	resetLocationNodes,
	selectLocationNodes,
	selectSelected
} from '../Location/statsLocationSlice';

const useLocationNodeStatisticsDeps = () => {
	return {
		fetchLocationStatsAsync,
		setResetAndReload,
		setSelected,
		setOffset,
		resetLocationNodes,
		selectLocationNodes,
		selectSelected
	};
};

export default useLocationNodeStatisticsDeps;
