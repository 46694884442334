import React from 'react';
import { Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = (props) => {
	return makeStyles((theme) => {
		let breakpointsSettings = {};

		for (const br in props.breakpoints) {
			breakpointsSettings[theme.breakpoints.down(br)] = {
				gridTemplateColumns: props.breakpoints[br]
			};
		}
		return {
			containerRoot: {
				minHeight: props.minHeight
			},
			grid: {
				display: 'grid',
				gridTemplateColumns: props.gridTemplateColumns,
				gridAutoRows: `minmax(${props.minCardHeight}px, auto)`,
				alignItems: 'center',
				gap: props.gap,
				padding: props.gridPadding,
				...breakpointsSettings
			}
		};
	});
};

const CardsGridBase = ({
	minHeight = 0,
	gridPadding,
	gridTemplateColumns = '1fr 1fr',
	breakpoints = { lg: '1fr 1f', md: '1fr' },
	minCardHeight = 50,
	gap = '10px 10px',
	children,
	...rest
}) => {
	const classes = useStyles({
		gridPadding: gridPadding,
		gridTemplateColumns: gridTemplateColumns,
		breakpoints: breakpoints,
		minCardHeight: minCardHeight,
		gap: gap,
		minHeight: minHeight
	})();

	return (
		<Box
			display='flex'
			flexDirection='column'
			flex={1}
			className={'cardsGridBase ' + classes.containerRoot}
			{...rest}>
			<Box className={classes.grid}>{children}</Box>
		</Box>
	);
};

export default CardsGridBase;
