import clsx from 'clsx';
import { Box, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		overflow: 'auto',
		position: 'relative'
	},
	rootStandalone: {
		padding: 0
	},
	container: {
		minWidth: '400px',
		padding: '1.5rem 1.5rem 0 1.5rem',
		margin: '0 auto',
		flex: 1,
		backgroundColor: theme.palette.background.default
	},
	containerStandalone: {
		flex: 1,
		height: '100%',
		overflow: 'hidden'
	}
}));

const ContentWrapper = (props) => {
	const classes = useStyles();

	return (
		<>
			<Box id='contentWrapper' className={clsx(classes.root, { [classes.rootStandalone]: props.standalone })}>
				{props.standalone ? (
					<Box className={classes.containerStandalone}>{props.children}</Box>
				) : (
					<Container className={classes.container}>{props.children}</Container>
				)}
			</Box>
			{!props.standalone && <div className='footer' style={{ paddingTop: '1.5rem' }} />}
		</>
	);
};

export default ContentWrapper;
