export default {
	Connected: {
		value: 0,
		name: 'connected'
	},
	Disconnected: {
		value: 1,
		name: 'disconnected'
	}
};
