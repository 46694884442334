import { useDispatch, useSelector } from 'react-redux';
import {
	selectRange,
	setDateRange,
	selectLayer,
	selectLocationsReadState,
	setLayer,
	setLocationsReadState,
	resetStatistics
} from './statisticsSlice';

export default () => {
	const dispatch = useDispatch();
	const dateRange = useSelector(selectRange);
	const layer = useSelector(selectLayer);
	const locationsReadState = useSelector(selectLocationsReadState);

	const updateDateRange = (option, date) => dispatch(setDateRange({ option, date }));

	const updateLayer = (value) => dispatch(setLayer(value));

	const updateLocationsReadState = (value) => dispatch(setLocationsReadState(value));

	const resetStats = () => dispatch(resetStatistics());

	return {
		dateRange,
		updateDateRange,
		layer,
		locationsReadState,
		updateLayer,
		updateLocationsReadState,
		resetStats
	};
};
