import React from 'react';
import { InputAdornment, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../hooks';

const useStyles = makeStyles(() => ({
	root: {
		'& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			display: 'none'
		}
	}
}));

const ConfiguratorOmsFrameSendingPeriod = ({
	onChange,
	onBlur,
	name,
	value,
	error,
	helperText,
	element,
	dataAtError
}) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const { unit, description = '', isReadOnly } = element;

	const helperTextValue = React.useMemo(() => {
		if (helperText && description) return `${helperText} (${translate(description)})`;
		if (helperText) return helperText;
		if (description) return translate(description);
		return '';
	}, [helperText]);

	const handleChange = (e) => {
		const nextValue = e.target.value;
		const isMatch = /^-?\d*$/.test(nextValue);

		if (isMatch) {
			onChange(e);
		}
	};

	return (
		<TextField
			margin='normal'
			size='small'
			type='number'
			fullWidth
			label={translate(name)}
			name={name}
			value={value ?? ''}
			disabled={Boolean(isReadOnly)}
			onChange={handleChange}
			onBlur={onBlur}
			error={error}
			helperText={helperTextValue}
			classes={{ root: classes.root }}
			FormHelperTextProps={{
				'data-at-error': dataAtError
			}}
			InputProps={{
				endAdornment: unit ? (
					<InputAdornment position='end'>
						<Typography variant='caption' color='textSecondary'>
							{translate(unit)}
						</Typography>
					</InputAdornment>
				) : null
			}}
		/>
	);
};

export default ConfiguratorOmsFrameSendingPeriod;
