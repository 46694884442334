import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import NotificationWrapper from './NotificationWrapper';

const Error = ({ title, message }) => {
	const dataAtError = typeof message === 'string' ? `notification|error|${message}` : null;

	return (
		<NotificationWrapper
			Icon={ReportOutlinedIcon}
			iconProps={{ color: 'error' }}
			title={title}
			message={message}
			wrapperProps={{
				'data-at': dataAtError
			}}
		/>
	);
};

export default Error;
