import { createSelector, createSlice } from '@reduxjs/toolkit';
import { pageDescriptor, getSliceDescriptor } from '@sonar-web/common';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import { fetchLocationStats } from './statsLocationApi';
import { slices } from '../Constants/Module';
import {
	levelTypesInitialState,
	levelTypesReducers,
	levelTypesStateName
} from './LocationsLevelTypes/locationsLevelTypesSlice';
import { readsMapInitialState, readsMapStateName, readsMapReducers } from './LocationsReadsMap/locationsReadsMapSlice';
import {
	buildingVisualizationStateName,
	initialBuildingVisualizationData,
	buildingVisualizationReducers
} from './BuildingVisualization/buildingVisualizationSlice';

export const slice = slices.statsLocation;

export const initialState = {
	pageDescriptor,
	pending: false,
	totalCount: 0,
	hasMore: true,
	reset: false,
	totalPoints: 0,
	totalRead: 0,
	ratio: 0,
	success: false,
	elements: [],
	selected: null,
	initialLoad: true
};

export const statsLocationSlice = createSlice({
	name: slice,
	initialState: {
		...initialState,
		[levelTypesStateName]: levelTypesInitialState,
		[readsMapStateName]: readsMapInitialState,
		[buildingVisualizationStateName]: initialBuildingVisualizationData
	},
	reducers: {
		fetchLocationStatsSucces: (state, { payload }) => {
			const { elements, totalCount, totalPoints, totalRead, ratio } = payload;

			state.elements = state.pageDescriptor.Offset === 0 ? elements : [...state.elements, ...elements];
			state.totalCount = totalCount;
			state.pending = false;
			state.reset = false;
			state.totalPoints = totalPoints;
			state.totalRead = totalRead;
			state.ratio = ratio;
			state.hasMore = totalCount > state.pageDescriptor.Offset + state.pageDescriptor.Limit;
			state.success = true;
		},
		fetchLocationStatsPending: (state, action) => {
			state.pending = action.payload;
		},
		setResetAndReload: (state, action) => {
			state.reset = action.payload ?? true;
			state.pageDescriptor.Offset = 0;
			state.success = false;
		},
		setOffset: (state, action) => {
			state.pageDescriptor.Offset = action.payload ? state.pageDescriptor.Offset + state.pageDescriptor.Limit : 0;
		},
		setSelected: (state, action) => {
			state.selected = action.payload;
		},
		resetLocationNodes: (state) => {
			state.elements = [];
			state.totalPoints = 0;
			state.totalRead = 0;
			state.ratio = 0;
			state.success = false;
		},
		setInitialLoad: (state, { payload }) => {
			state.initialLoad = payload ?? false;
		},
		...levelTypesReducers,
		...readsMapReducers,
		...buildingVisualizationReducers
	}
});

export const {
	fetchLocationStatsSucces,
	fetchLocationStatsPending,
	setResetAndReload,
	setSelected,
	setOffset,
	resetLocationNodes,
	setInitialLoad
} = statsLocationSlice.actions;

export const fetchLocationStatsAsync = () => async (dispatch, getState) => {
	try {
		const pd = getSliceDescriptor(
			getState,
			slice,
			[],
			[
				{ Member: 'locationNodeLevelType', ListSortDirection: 1 },
				{ Member: 'displayNameToSort', ListSortDirection: 1 }
			]
		);

		dispatch(fetchLocationStatsPending(true));

		const response = await fetchLocationStats(pd);

		dispatch(fetchLocationStatsSucces(response));
		if (getState().statsLocation.initialLoad) dispatch(setInitialLoad());
	} catch (error) {
		dispatch(fetchLocationStatsPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectLocationNodes = createSelector(
	(state) => state.statsLocation,
	(statsLocation) => ({
		dataRows: statsLocation.elements,
		pending: statsLocation.pending,
		hasMore: statsLocation.hasMore,
		reset: statsLocation.reset,
		totalPoints: statsLocation.totalPoints,
		totalRead: statsLocation.totalRead,
		ratio: statsLocation.ratio,
		success: statsLocation.success
	})
);

export const selectSelected = (state) => state.statsLocation.selected;

export const selectInitalLoad = (state) => state.statsLocation.initialLoad;

export default statsLocationSlice.reducer;
