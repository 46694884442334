import { Typography, Radio, RadioGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '@sonar-web/common/src/hooks';
import TileBase from '@sonar-web/common/src/features/Tiles/TileBase';
import useStatistics from '@sonar/stats/src/Statistics/useStatistics';

const useStyles = makeStyles((theme) => ({
	tileContent: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		padding: '6px 8px'
	},
	radioRoot: {
		padding: 4,
		marginRight: 2,
		'& svg': {
			fontSize: '1rem'
		}
	},
	lableRoot: {
		marginTop: 8,
		whiteSpace: 'pre-line',
		textAlign: 'center'
	},
	icon: {
		color: theme.palette.text.primary
	},
	selectedIcon: {
		color: theme.palette.primary.main
	}
}));

const FiltersLayersTile = ({ title, value }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const { updateLayer, layer } = useStatistics();
	const selected = layer === value;

	const handleChangeLayer = () => {
		if (value !== layer) updateLayer(value);
	};

	return (
		<TileBase
			onClick={handleChangeLayer}
			selectedBackgroundEffect={true}
			selected={selected}
			data-at='radioItem'
			renderContent={() => {
				return (
					<div className={classes.tileContent}>
						<RadioGroup value={layer}>
							<Radio
								value={value}
								color='primary'
								size='small'
								classes={{ root: classes.radioRoot }}
								data-at={selected ? 'radioSelected' : 'radioNotSelected'}
							/>
						</RadioGroup>
						<Typography variant='caption' color='textPrimary' className={classes.tileText} data-at={title}>
							{translate(title)}
						</Typography>
					</div>
				);
			}}
		/>
	);
};

export default FiltersLayersTile;
