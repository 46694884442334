export default {
	Waiting: {
		value: 0,
		name: 'Waiting'
	},
	InProgress: {
		value: 2,
		name: 'InProgress'
	},
	Done: {
		value: 3,
		name: 'Done'
	},
	AssignedToContractor: {
		value: 4,
		name: 'AssignedToContractor'
	},
	Problem: {
		value: 5,
		name: 'Problem'
	},
	Unfulfilled: {
		value: 6,
		name: 'Unfulfilled'
	},
	Cancelled: {
		value: -1,
		name: 'Cancelled'
	}
};
