import { lazy } from 'react';
import { ROUTE_GUID } from '@sonar-web/common';
import { Features } from '@sonar/auth';

const NetworksPage = lazy(() => import('./Networks/NetworksPage'));
const NetworkPage = lazy(() => import('./Network/NetworkPage'));

export default [
	{
		component: NetworksPage,
		feature: Features.NetworksPage.name,
		path: '/stationarynetwork',
		exact: true,
		name: 'Common_StationaryNetwork'
	},
	{
		component: NetworkPage,
		feature: Features.NetworkPage.name,
		path: `/stationarynetwork/:id(${ROUTE_GUID})`,
		exact: true,
		name: '{0}'
	}
];
