export default {
	ReadSpontaneousFrame: {
		value: 9,
		name: 'ReadSpontaneousFrame',
		includeInFilter: true
	},
	Montage: {
		value: 1,
		name: 'Montage',
		includeInFilter: true
	},
	Replacement: {
		value: 0,
		name: 'Replacement',
		includeInFilter: true
	},
	ReadConfiguration: {
		value: 8,
		name: 'ReadConfiguration',
		includeInFilter: true
	},
	WriteConfiguration: {
		value: 7,
		name: 'WriteConfiguration',
		includeInFilter: true
	},
	Programming: {
		value: 4,
		name: 'Programming',
		includeInFilter: true
	},
	UnMontage: {
		value: 2,
		name: 'UnMontage'
	},
	Custom: {
		value: -1,
		name: 'Custom'
	},
	GetCoordinates: {
		value: 5,
		name: 'GetCoordinates'
	},
	MeshNetworkConfig: {
		value: 10,
		name: 'MeshNetworkConfig',
		includeInFilter: true
	},
	MontageUnMontage: {
		value: 100,
		name: 'MontageUnMontage'
	},
	FirmwareWrite: {
		value: 3,
		name: 'FirmwareWrite'
	}
};
