import React from 'react';
import { Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	timelineConnector: {
		flex: 1,
		minHeight: 2,
		maxHeight: 2,
		backgroundColor: theme.palette.grey[400],
		marginRight: 2,
		marginLeft: 2
	}
}));

const Connector = ({ ...rest }) => {
	const classes = useStyles();

	return <Box className={classes.timelineConnector} {...rest}></Box>;
};

export default Connector;
