import FetchTimeLicenses from '../TimeLicense/FetchTimeLicenses';
import TenantsSelector from './TenantsSelector';
import { AuthService } from '@sonar/auth';

const TenantsPage = (props) => {
	const tenants = AuthService.getTenants();

	return (
		<FetchTimeLicenses>
			{(licensesProps) => <TenantsSelector {...props} tenants={tenants} licensesProps={licensesProps} />}
		</FetchTimeLicenses>
	);
};

export default TenantsPage;
