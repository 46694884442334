import { createSelector, createSlice } from '@reduxjs/toolkit';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import HeatMeterService from './HeatMeterService';
import Module, { slices } from '../Constants/Module';
import { getSliceDescriptor } from '@sonar-web/common';

const getHeatMeter = (getState) => getState().heatMeter.heatMeter;

const slice = Module.slices.heatMeter;

export const heatMeterSlice = createSlice({
	name: slice,
	initialState: {
		addPending: false,
		addSuccess: false,
		fetchPending: false,
		fetchSuccess: false,
		deletePending: false,
		deleteSuccess: false,
		heatMeter: null,
		metaData: null,
		fetchMetaDataPending: false,
		editMetaDataPending: false,
		exportReadsPending: false,
		exportReads: null,
		diameterAndLength: null,
		diameterAndLengthPending: false
	},
	reducers: {
		fetchHeatMeterSucces: (state, { payload }) => {
			state.heatMeter = payload;
			state.fetchPending = false;
			state.fetchSuccess = true;
		},
		fetchHeatMeterPending: (state) => {
			state.fetchPending = true;
			state.fetchSuccess = false;
		},
		addHeatMeterSucces: (state, { payload }) => {
			state.addPending = false;
			state.addSuccess = payload;
		},
		addHeatMeterPending: (state, { payload }) => {
			state.addPending = payload ?? true;
		},
		deleteHeatMeterSucces: (state) => {
			state.deletePending = false;
			state.deleteSuccess = true;
		},
		deleteHeatMeterPending: (state, { payload }) => {
			state.deletePending = payload ?? true;
		},
		setHeatMeterData: (state, { payload }) => {
			state.heatMeter = payload;
		},
		resetHeatMeterData: (state) => {
			state.heatMeter = null;
			state.addSuccess = false;
			state.deleteSuccess = false;
			state.fetchSuccess = false;
		},
		resetAddSuccess: (state) => {
			state.addSuccess = false;
		},
		fetchMetaDataPending: (state, { payload }) => {
			state.fetchMetaDataPending = payload;
		},
		fetchMetaDataSuccess: (state, { payload }) => {
			state.fetchMetaDataPending = false;
			state.metaData = payload;
		},
		setMetaData: (state, { payload }) => {
			state.metaData = payload || null;
		},
		editMetaDataPending: (state, { payload }) => {
			state.editMetaDataPending = payload;
		},
		fetchExportReadsSuccess: (state, { payload }) => {
			state.exportReadsPending = false;
			state.exportReads = payload?.elements;
		},
		fetchExportReadsPending: (state, { payload }) => {
			state.exportReadsPending = payload;
		},
		setDiameterAndLength: (state, { payload }) => {
			state.diameterAndLength = !payload.data
				? state.diameterAndLength
				: payload.data.map((d) => ({ ...d, valueKey: `DN ${d.diameter} L ${d.length}` }));
			state.diameterAndLengthPending = payload.pending;
		}
	}
});

export const {
	fetchHeatMeterSucces,
	fetchHeatMeterPending,
	addHeatMeterSucces,
	addHeatMeterPending,
	deleteHeatMeterSucces,
	deleteHeatMeterPending,
	setHeatMeterData,
	resetHeatMeterData,
	resetAddSuccess,
	fetchMetaDataPending,
	fetchMetaDataSuccess,
	setMetaData,
	editMetaDataPending,
	fetchExportReadsPending,
	fetchExportReadsSuccess,
	setDiameterAndLength
} = heatMeterSlice.actions;

export const fetchHeatMeterAsync = (id) => async (dispatch) => {
	try {
		dispatch(fetchHeatMeterPending());
		const response = await HeatMeterService.fetchHeatMeter(id);
		dispatch(fetchHeatMeterSucces(response));
	} catch (error) {
		dispatch(fetchHeatMeterPending(false));
		dispatch(addErrorAsync({ slice, error }));
		return;
	}
};

export const addHeatMeterAsync = () => async (dispatch, getState) => {
	const heatMeter = { ...getHeatMeter(getState) };
	let result = Object.assign({}, heatMeter);

	delete heatMeter.heatMeterTypeName;

	try {
		dispatch(addHeatMeterPending());

		const response = await HeatMeterService.addHeatMeter(heatMeter);
		result.id = response.id;

		dispatch(addHeatMeterSucces(response.id ? result : true));
	} catch (error) {
		dispatch(addHeatMeterPending(false));

		return dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['number'] }));
	}
};

export const editHeatMeterAsync = () => async (dispatch, getState) => {
	const heatMeter = getHeatMeter(getState);

	try {
		dispatch(addHeatMeterPending());
		await HeatMeterService.editHeatMeter(heatMeter);
		dispatch(addHeatMeterSucces(true));
		setTimeout(() => dispatch(resetAddSuccess()), 200);
	} catch (error) {
		dispatch(addHeatMeterPending(false));

		return dispatch(addErrorAsync({ slice, error, skipNotificationMembers: ['number'] }));
	}
};

export const deleteHeatMeterAsync = (id) => async (dispatch) => {
	try {
		dispatch(deleteHeatMeterPending());
		await HeatMeterService.deleteHeatMeter(id);
		dispatch(deleteHeatMeterSucces());
	} catch (error) {
		dispatch(deleteHeatMeterPending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchHeatMeterMetaDataAsync = (id) => async (dispatch) => {
	try {
		dispatch(fetchMetaDataPending(true));
		const response = await HeatMeterService.fetchHeatMeterMetaData(id);
		dispatch(fetchMetaDataSuccess(response));
	} catch (error) {
		dispatch(fetchMetaDataPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const editHeatMeterMetaDataAsync = (id) => async (dispatch, getState) => {
	try {
		dispatch(editMetaDataPending(true));
		const metaData = getState().heatMeter.metaData;
		await HeatMeterService.editHeatMeterMetaData(id, metaData);
		dispatch(editMetaDataPending(false));
	} catch (error) {
		dispatch(editMetaDataPending(false));
		return dispatch(addErrorAsync({ slice, error }));
	}
};

export const fetchHeatMeterExportReadsAsync = (filters) => async (dispatch, getState) => {
	try {
		const pd = getSliceDescriptor(getState, slices.heatMeterReads, null, null, Math.pow(2, 31) - 1);
		pd.FilterDescriptors = [...pd.FilterDescriptors, ...filters];
		pd.Offset = 0;

		dispatch(fetchExportReadsPending(true));
		const response = await HeatMeterService.fetchHeatMeterReads(pd);
		dispatch(fetchExportReadsSuccess(response));
		return response;
	} catch (error) {
		dispatch(fetchExportReadsPending(false));
		dispatch(addErrorAsync({ slice: slices.heatMeterReads, error }));
	}
};

export const fetchDiameterAndLengthAsync = (id) => async (dispatch) => {
	try {
		dispatch(setDiameterAndLength({ pending: true }));
		const response = await HeatMeterService.fetchDiameterAndLength(id);
		dispatch(setDiameterAndLength({ pending: false, data: response }));
	} catch (error) {
		dispatch(setDiameterAndLength({ pending: false }));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const selectHeatMeter = createSelector(
	(state) => state.heatMeter,
	(heatMeter) => ({
		dataRow: heatMeter.heatMeter,
		fetchPending: heatMeter.fetchPending,
		fetchSuccess: heatMeter.fetchSuccess
	})
);

export const selectHeatMeterAdd = createSelector(
	(state) => state.heatMeter,
	(heatMeter) => ({
		addPending: heatMeter.addPending,
		addSuccess: heatMeter.addSuccess
	})
);

export const selectDeleteHeatMeterPending = createSelector(
	(state) => state.heatMeter,
	(heatMeter) => ({
		deletePending: heatMeter.deletePending,
		deleteSuccess: heatMeter.deleteSuccess
	})
);

export const selectHeatMeterMetaData = createSelector(
	(state) => state.heatMeter,
	(heatMeter) => ({
		pending: heatMeter.fetchMetaDataPending,
		metaData: heatMeter.metaData
	})
);

export const selectEditMetaData = createSelector(
	(state) => state.heatMeter,
	(heatMeter) => ({
		pending: heatMeter.editMetaDataPending
	})
);

export const selectHeatMeterExportReads = createSelector(
	(state) => state.heatMeter,
	(heatMeter) => ({
		pending: heatMeter.exportReadsPending,
		exportReads: heatMeter.exportReads
	})
);

export const selectDiameterAndLength = createSelector(
	(state) => state.heatMeter,
	(heatMeter) => ({
		pending: heatMeter.diameterAndLengthPending,
		diameterAndLength: heatMeter.diameterAndLength
	})
);

export default heatMeterSlice.reducer;
