import { useState } from 'react';
import { Menu } from '@mui/material';
import IconButtonMore from '@sonar-web/common/src/components/Buttons/IconButtonMore';

const TableListItemMenu = ({ items, disabled = false, children }) => {
	const [anchor, setAnchor] = useState(null);

	const openMenu = (e) => {
		e.stopPropagation();
		e.preventDefault();

		setAnchor(e.currentTarget);
	};

	const closeMenu = (e) => {
		e.stopPropagation();
		e.preventDefault();

		setAnchor(null);
	};

	const menuItems = items ? items({ closeMenu }) : [];

	if (!children && !menuItems.length) return null;
	return (
		<>
			<IconButtonMore name='tableListItemMenuButton' onClick={openMenu} color='primary' disabled={disabled} />
			<Menu
				anchorEl={anchor}
				keepMounted
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={Boolean(anchor)}
				onClose={closeMenu}
				data-at='tableListItemMenu'>
				{children ?? menuItems.map((Item, i) => <Item key={i} />)}
			</Menu>
		</>
	);
};

export default TableListItemMenu;
