import { useEffect, useMemo, useState } from 'react';
import DOMPurify from 'dompurify';
import { Toolbar, Typography, Container, Stack } from '@mui/material';
import useLocale from '@sonar-web/common/src/hooks/useLocale';
import { useCrossTabState } from '@sonar-web/common/src/hooks';
import ContentWrapper from '@sonar-web/common/src/features/ContentWrapper/ContentWrapper';
import { AuthService } from '@sonar/auth';
import logo from '@sonar-web/common/src/images/sonar-logo.svg';
import useCurrentTenant from '../Hooks/useCurrentTenant';
import Logout from './Logout';
import SelectTenant from './SelectTenant';
import TenantsList from './TenantsList';

const TenantsSelector = ({ tenants, licensesProps, children }) => {
	const { translate } = useLocale();
	const { getStoredTenant, storeTenant } = useCurrentTenant();
	const lastSelectedTenant = getStoredTenant();
	const [tenantSelected, setTenantSelected] = useState(null);
	const [visuallyHidden, setVisuallyHidden] = useState(true);

	useCrossTabState('tenant', (v) => {
		storeTenant(JSON.parse(DOMPurify.sanitize(v)));
	});

	const sessionChanged = useMemo(() => AuthService.sessionChanged(), []);

	const noTenants = tenants.length === 0;

	const onTenantSelect = (t) => {
		storeTenant(t);
		setTenantSelected(t);
	};

	const selctTenantWithActiveLicense = (tenant) => {
		const tenantLicenseData = licensesProps.timeLicenses.find((tl) => tl.id === tenant.id);
		const tenantData = tenants.find((t) => t.id === tenant.id);

		if (tenantLicenseData.isValid) setTenantSelected({ ...tenantLicenseData, name: tenantData.name });
	};

	useEffect(() => {
		let visuallyHiddenTimer;

		if (licensesProps.timeLicensesPending && !visuallyHiddenTimer)
			setTimeout(() => {
				setVisuallyHidden(false);
			}, 300);

		if (!licensesProps.timeLicenses) return;

		if (tenants.length === 1) {
			selctTenantWithActiveLicense(tenants[0]);
			return;
		}

		if (sessionChanged || !lastSelectedTenant || noTenants) return;

		selctTenantWithActiveLicense(lastSelectedTenant);

		return () => {
			if (visuallyHiddenTimer) clearTimeout(visuallyHiddenTimer);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [licensesProps.timeLicensesPending]);

	if (tenantSelected) return <SelectTenant tenant={tenantSelected}>{children}</SelectTenant>;

	const title = noTenants ? 'NoTenantsTitle' : 'TenantsTitle';
	const subtitle = noTenants ? 'NoTenantsSubtitle' : 'TenantsSubtitle';

	return (
		<Stack
			sx={{
				visibility: visuallyHidden ? 'hidden' : 'visible'
			}}>
			<Toolbar disableGutters>
				<Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<img
						src={logo}
						alt='Spidap Logo'
						height={43}
						style={{
							verticalAlign: 'middle'
						}}
					/>
					<Logout />
				</Container>
			</Toolbar>
			<ContentWrapper>
				<Typography variant='h5' data-at={title}>
					{translate(title)}
				</Typography>
				<Typography variant='caption' color='textSecondary' data-at={subtitle}>
					{translate(subtitle)}
				</Typography>
				<TenantsList tenants={tenants} timeLicenses={licensesProps.timeLicenses} onSelect={onTenantSelect} />
			</ContentWrapper>
		</Stack>
	);
};

export default TenantsSelector;
