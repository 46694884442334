import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEqual } from 'lodash';
import { usePrevious } from '@sonar-web/common/src/hooks';
import { BaseLinearProgress, ListNoRecords, guidEmpty, CustomSelectInlineText } from '@sonar-web/common';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useLocale from '@sonar-web/common/src/hooks/useLocale';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import BaseButton from '@sonar-web/common/src/components/Buttons/BaseButton';
import DeviceDiagnosticReadsTimeline from './DiagnosticReadsTimeline/DeviceDiagnosticReadsTimeline';
import TerminalDiagnosticReadsList from './DiagnosticReadsList/TerminalDiagnosticReadsList';
import DiagnosticMoreDataInfo from './DiagnosticMoreDataInfo';
import { fetchTerminalNetworks } from './terminalDiagnosticApi';

const useStyles = makeStyles((theme) => ({
	gridTitle: {
		fontWeight: theme.typography.fontWeightMedium
	},
	selectOptionWrapper: {
		padding: 0,
		display: 'flex',
		flex: 1,

		marginTop: 0,
		marginBottom: theme.spacing(2)
	}
}));

const TerminalDiagnostic = ({ deviceId, isDashboard }) => {
	const { translate } = useLocale();
	const history = useHistory();

	const classes = useStyles();

	const dispatch = useDispatch();

	const [networks, setNetworks] = useState([]);

	const [selectedNetwork, setSelectedNetwork] = useState(null);

	const [fetchNetworksPending, setFetchNetworksPending] = useState(false);

	const previousDeviceId = usePrevious(deviceId);

	const [showHistory, setShowHistory] = useState(false);

	const fetchNetworksAsync = (params) => async () => {
		let response;

		try {
			setFetchNetworksPending(true);
			response = await fetchTerminalNetworks(params);
		} catch (error) {
			dispatch(addErrorAsync({ slice: 'wmbusDevice', error }));
		}

		setFetchNetworksPending(false);
		return response;
	};

	useEffect(() => {
		return () => {
			setSelectedNetwork(null);
			setShowHistory(false);
		};
	}, []);

	useEffect(() => {
		if (fetchNetworksPending) return;

		if (!deviceId || deviceId == guidEmpty) return;
		const equalDeviceId = isEqual(deviceId, previousDeviceId);

		if (equalDeviceId) return;

		dispatch(fetchNetworksAsync({ deviceId })).then((res) => {
			const networks = res?.networks?.map((opt) => ({
				value: opt.id,
				label: opt.name
			}));
			setNetworks(networks);

			if (networks && networks.length > 0) {
				if (selectedNetwork) {
					let selected = false;

					for (var i = 0; i < networks.length; i++) {
						if (isEqual(networks[i].value, selectedNetwork.value)) {
							selected = true;
							setSelectedNetwork(networks[i]);
							break;
						}
					}
					if (!selected) {
						setSelectedNetwork(networks[0]);
					}
				} else {
					setSelectedNetwork(networks[0]);
				}
			} else {
				setSelectedNetwork(null);
			}
		});
	}, [deviceId]);

	const handleNetworkChange = (option) => {
		setSelectedNetwork(option);
	};

	return (
		<Box display='flex' flexDirection='column' height='100%' maxHeight='100%' justifyContent='flex-start' pb={15}>
			{fetchNetworksPending ? (
				<BaseLinearProgress pending={fetchNetworksPending} />
			) : selectedNetwork ? (
				<>
					<Box display='flex' flexDirection='row'>
						<Box display='flex' flex={1} flexDirection='row' alignItems='center'>
							<Typography variant='h6'>
								{translate('Wmbus_DiagnosticOfDeviceCommunicationInNetwork')}
							</Typography>
							<Box ml={1}>
								<CustomSelectInlineText
									width={180}
									height={30}
									options={networks}
									value={selectedNetwork}
									onChange={handleNetworkChange}
									menuButtonName='DeviceConfigurator_ActionSelectMore'
								/>
							</Box>
						</Box>
						<Box>
							<BaseButton
								variant='outlined'
								name='billingClientProfileShow'
								onClick={() => history.push(`/stationarynetwork/${selectedNetwork.value}`)}>
								Wmbus_CheckNetworkSettings
							</BaseButton>
						</Box>
					</Box>
					<DeviceDiagnosticReadsTimeline deviceId={deviceId} networkId={selectedNetwork.value} />
					{showHistory ? (
						<Box flex='column' height='100%' mt={2}>
							<Typography variant='body2' classes={{ root: classes.gridTitle }}>
								{translate('Wmbus_DetailedScheduleOfDeviceCommunication')}
							</Typography>
							<TerminalDiagnosticReadsList
								deviceId={deviceId}
								networkId={selectedNetwork.value}
								isDashboard={isDashboard}
							/>
						</Box>
					) : (
						<DiagnosticMoreDataInfo
							onClick={() => {
								setShowHistory(true);
							}}
						/>
					)}
				</>
			) : (
				<ListNoRecords show={true} text='Wmbus_DeviceInNetworkDiagnosticEmpty' />
			)}
		</Box>
	);
};

export default TerminalDiagnostic;
