import React from 'react';
import { TextField } from '@mui/material';
import { useLocale } from '../../hooks';

const FormTextField = ({ name, label, value, formik, helperText = '', ...rest }) => {
	const { translate } = useLocale();
	const { values, errors, touched, handleChange, handleBlur } = formik;
	const error = errors[name];
	const touch = touched[name];
	const val = value ?? values[name];

	return (
		<TextField
			margin='normal'
			name={name}
			label={translate(label)}
			type='text'
			size='small'
			fullWidth
			value={val}
			onChange={handleChange}
			onBlur={handleBlur}
			error={Boolean(error && touch)}
			helperText={error && touch ? translate(error) : helperText}
			FormHelperTextProps={{
				'data-at-error': error
			}}
			{...rest}
		/>
	);
};

export default FormTextField;
