import React from 'react';
import { Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = (props) => {
	return makeStyles((theme) => {
		return {
			timelineText: {
				fontSize: theme.typography.pxToRem(10),
				color: props.color,
				fontWeight: theme.typography.fontWeightMedium,
				minHeight: props.size,
				//minWidth: props.size,
				paddingLeft: 1,
				paddingRight: 1
			},
			timelineDot: {
				borderRadius: '50%',
				height: props.size,
				width: props.size,
				display: 'flex',
				backgroundColor: props.color
			}
		};
	});
};

const TimelinePoint = ({ text, color, size, ...rest }) => {
	const classes = useStyles({
		size,
		color
	})();

	if (text) {
		return (
			<Box display='flex' flexDirection='row' alignItems='center'>
				<Box {...rest} className={classes.timelineDot}></Box>
				<Box {...rest} className={classes.timelineText}>
					{text}
				</Box>
			</Box>
		);
	} else {
		return <Box {...rest} className={classes.timelineDot}></Box>;
	}
};

export default TimelinePoint;
