import { useEffect, useMemo, useState } from 'react';
import { InputAdornment, TextField, Typography } from '@mui/material';
import { useLocale } from '../../../../hooks';

const ConfiguratorNumberfield = ({
	onChange,
	onBlur,
	name,
	value,
	error,
	helperText,
	element,
	dataAtError,
	form,
	group,
	forceUpdateValidationSchema
}) => {
	const { translate } = useLocale();
	const { dependsOn, unit, description = '', defaultValue, isReadOnly, step } = element;
	const isDependent = dependsOn !== null;

	const dependencyValue = useMemo(() => {
		if (!isDependent || (isDependent && form.values[dependsOn.key] == null)) return false;
		if (dependsOn.isEqual === false) return dependsOn.value !== form.values[dependsOn.key];
		return dependsOn.value === form.values[dependsOn.key];
	}, [dependsOn?.value, form.values[dependsOn?.key]]);

	const helperTextValue = useMemo(() => {
		if (helperText && description) return `${helperText} (${translate(description, element.min, element.max)})`;
		if (helperText) return helperText;
		if (description) return translate(description, element.min, element.max);
		return '';
	}, [helperText]);

	const [dependencyVisibility, setDependencyVisibility] = useState(dependencyValue);

	const handleChange = (e) => {
		const nextValue = e.target.value;
		const isMatch = /^-?\d{0,10}[.,]?\d{0,3}$/.test(nextValue);

		if (isMatch) onChange(e);
	};

	useEffect(() => {
		if (!dependsOn) return;

		setDependencyVisibility(dependencyValue);
		if (group.included) forceUpdateValidationSchema(form.values);
	}, [form.values[dependsOn?.key]]);

	useEffect(() => {
		if (!dependsOn) return;
		if (!dependencyVisibility) form.setFieldValue(name, defaultValue);
	}, [dependencyVisibility]);

	if (dependsOn && !dependencyVisibility) return null;
	return (
		<TextField
			type='number'
			margin='normal'
			size='small'
			inputProps={{
				inputMode: 'decimal',
				step: step ?? 0.001
			}}
			fullWidth
			label={translate(name)}
			name={name}
			value={value ?? ''}
			disabled={Boolean(isReadOnly)}
			onChange={handleChange}
			onBlur={onBlur}
			error={error}
			helperText={helperTextValue}
			FormHelperTextProps={{
				'data-at-error': dataAtError
			}}
			InputProps={{
				endAdornment: unit ? (
					<InputAdornment position='end'>
						<Typography variant='caption' color='textSecondary'>
							{translate(unit)}
						</Typography>
					</InputAdornment>
				) : null
			}}
		/>
	);
};

export default ConfiguratorNumberfield;
