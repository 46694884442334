import { useMemo } from 'react';
import { Typography, Radio, RadioGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '@sonar-web/common/src/hooks';
import TileBase from '@sonar-web/common/src/features/Tiles/TileBase';
import useStatistics from '@sonar/stats/src/Statistics/useStatistics';
import { formatDateTime } from '@sonar-web/common';
import { DateRangeOptions } from '@sonar-web/common/src/features/DateRange/DateRangeOptions';

const useStyles = makeStyles((theme) => ({
	tileContent: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		padding: '6px 8px'
	},
	radioRoot: {
		padding: 4,
		marginRight: 2,
		'& svg': {
			fontSize: '1rem'
		}
	},
	lableRoot: {
		marginTop: 8,
		whiteSpace: 'pre-line',
		textAlign: 'center'
	},
	icon: {
		color: theme.palette.text.primary
	},
	selectedIcon: {
		color: theme.palette.primary.main
	}
}));

const FiltersDateRangeTile = ({ title, value, dataat }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const { updateDateRange, dateRange } = useStatistics();
	const selected = dateRange.dateRangeOption === value;
	const isOwn = value === DateRangeOptions.Own.value;

	const handleChangeLayer = () => {
		if (value !== dateRange.dateRangeOption) updateDateRange(value, isOwn ? dateRange.dateRangeDate : null);
	};

	const text = useMemo(() => {
		if (!selected) return translate(title);

		const date = formatDateTime(dateRange.dateRangeDate);

		return `${translate(title)} (${date} - ${translate('Now')})`;
	}, [dateRange.dateRangeDate, dateRange.dateRangeOption, selected]);

	return (
		<TileBase
			onClick={handleChangeLayer}
			selectedBackgroundEffect={true}
			selected={selected}
			data-at='radioItem'
			renderContent={() => {
				return (
					<div className={classes.tileContent}>
						<RadioGroup value={dateRange.dateRangeOption}>
							<Radio
								value={value}
								color='primary'
								size='small'
								classes={{ root: classes.radioRoot }}
								data-at={selected ? 'radioSelected' : 'radioNotSelected'}
							/>
						</RadioGroup>
						<Typography
							variant='caption'
							color='textPrimary'
							className={classes.tileText}
							data-at={isOwn ? `Date_${formatDateTime(dateRange.dateRangeDate, 'date')}` : dataat}>
							{translate(text)}
						</Typography>
					</div>
				);
			}}
		/>
	);
};

export default FiltersDateRangeTile;
