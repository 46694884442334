import React from 'react';
import { Tooltip, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = (props) => {
	return makeStyles((theme) => {
		return {
			tooltip: {
				marginTop: 15,
				backgroundColor: props.tooltipBackground,
				fontSize: theme.typography.pxToRem(12),
				fontWeight: theme.typography.fontWeightLight,
				padding: 10,
				borderRadius: 8,
				color: props.tooltipTextColor,
				border: props.tooltipBorder
			},
			tooltipPopper: {
				zIndex: 1
			},
			tooltipArrow: {
				color: props.tooltipBackground,
				'&:before': {
					border: props.tooltipBorder
				}
			}
		};
	});
};

const TimelineBottomTooltip = ({ children, title, text, tooltipBackground, tooltipTextColor, tooltipBorder }) => {
	const classes = useStyles({
		tooltipBackground,
		tooltipTextColor,
		tooltipBorder
	})();

	return (
		<Tooltip
			zindex={1}
			title={
				<React.Fragment>
					<Typography variant='body2'>{title}</Typography>
					{text}
				</React.Fragment>
			}
			arrow
			placement='bottom'
			open={true}
			classes={{ arrow: classes.tooltipArrow, tooltip: classes.tooltip, popper: classes.tooltipPopper }}>
			<div>{children}</div>
		</Tooltip>
	);
};

export default TimelineBottomTooltip;
