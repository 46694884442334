import { Box, Typography } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import { InterfaceType } from '@sonar-web/common';
import FiltersLayersTile from './FiltersLayersTile';

const options = [
	{
		value: InterfaceType.Everything.value,
		title: 'AllDevices'
	},
	{
		value: InterfaceType.ColdWaterMeasurement.value,
		title: 'ColdWatermeters'
	},
	{
		value: InterfaceType.WarmWaterMeasurement.value,
		title: 'WarmWatermeters'
	},
	{
		value: InterfaceType.HeatAllocating.value,
		title: 'Heatallocators'
	},
	{
		value: InterfaceType.AllHeatmeters.value,
		title: 'HeatMeters'
	}
];

const FiltersLayer = ({ title = 'Common_Devices' }) => {
	const { translate } = useLocale();

	return (
		<Box display='flex' flexDirection='column' rowGap={1} className='filtersSection filtersSectionFirst'>
			<Typography variant='body2'>{translate(title)}</Typography>
			{options.map((o) => {
				return <FiltersLayersTile key={o.value} {...o} />;
			})}
		</Box>
	);
};

export default FiltersLayer;
