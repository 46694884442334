import { ROUTE_GUID } from '@sonar-web/common';

export const moduleName = 'heatmeter';

export const slices = {
	heatMeter: 'heatMeter',
	heatMeterRead: 'heatMeterRead',
	heatMeterReads: 'heatMeterReads',
	heatMeterCharts: 'heatMeterCharts'
};

const Module = {
	moduleName,
	slices
};

export default Module;
