import { lazy } from 'react';
import { ROUTE_GUID } from '@sonar-web/common';
import { Features } from '@sonar/auth';

const ReportPage = lazy(() => import('./Report/ReportPage'));
const ReportConfiguratorEdit = lazy(() => import('./ReportConfigurator/ReportConfiguratorEdit'));
const ReportsPage = lazy(() => import('./Reports/ReportsPage'));
const ReportTypes = lazy(() => import('./ReportTypes/ReportTypes/ReportTypes'));

export default [
	{
		component: ReportsPage,
		feature: Features.ReportsPage.name,
		path: '/generatedReports',
		exact: true,
		name: 'Reports_GeneratedReports'
	},
	{
		component: ReportsPage,
		feature: Features.ReportsPage.name,
		path: '/scheduledReports',
		exact: true,
		name: 'Reports_ScheduledReports'
	},
	{
		component: ReportTypes,
		feature: Features.ReportConfigurator.name,
		path: '/reportConfigurator',
		exact: true,
		name: 'Reports_ReportTypes'
	},
	{
		component: ReportConfiguratorEdit,
		feature: Features.ReportConfigurator.name,
		path: `/reportConfigurator/:id(${ROUTE_GUID})`,
		exact: true,
		name: '{0}'
	},
	{
		component: ReportPage,
		feature: Features.ReportPage.name,
		path: `/generatedReports/report/:id(${ROUTE_GUID})`,
		exact: true,
		name: 'Reports_Report'
	}
];
