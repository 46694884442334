import React, { useState } from 'react';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../hooks';

const useStyles = makeStyles(() => ({
	root: {
		'& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			display: 'none'
		}
	},
	inputRoot: {
		width: 60
	}
}));

const ConfiguratorQprofileSingle = ({ index, label, unit, valuesPair, onChange }) => {
	const classes = useStyles();
	const { translate } = useLocale();
	const [from, setFrom] = useState(valuesPair.from);
	const [to, setTo] = useState(valuesPair.to);

	React.useEffect(() => {
		setFrom(valuesPair.from);
	}, [valuesPair.from]);

	const handleChange = (e) => {
		const newValue = e.target.value;

		onChange(index, newValue);
		setTo(newValue);
	};

	const handleOnBlur = (e) => {
		let newValue = e.target.value;

		if (newValue.indexOf('.') === newValue.length - 1) newValue = newValue.replace('.', '');
		if (newValue.indexOf(',') === newValue.length - 1) newValue = newValue.replace(',', '');
		if (newValue.includes('e')) newValue = newValue.replace('e', '');
		if (newValue === '') newValue = 0;

		onChange(index, newValue);
		setTo(newValue);
	};

	return (
		<>
			<Typography variant='body2'>{translate(label)}</Typography>
			<Box display='flex' mb={3}>
				<TextField
					type='number'
					margin='dense'
					size='small'
					disabled
					label={translate('Common_From')}
					value={from}
					classes={{ root: classes.root }}
					InputProps={{
						classes: { input: classes.inputRoot },
						endAdornment: unit ? (
							<InputAdornment position='end'>
								<Typography variant='caption' color='textSecondary'>
									{translate(unit)}
								</Typography>
							</InputAdornment>
						) : null
					}}
				/>
				<Box display='flex' alignItems='center' justifyContent='center' pt={3} width={60}>
					-
				</Box>
				<TextField
					type='number'
					margin='dense'
					size='small'
					label={translate('Common_To')}
					value={to}
					onChange={handleChange}
					onBlur={handleOnBlur}
					classes={{ root: classes.root }}
					InputProps={{
						classes: { input: classes.inputRoot },
						endAdornment: unit ? (
							<InputAdornment position='end'>
								<Typography variant='caption' color='textSecondary'>
									{translate(unit)}
								</Typography>
							</InputAdornment>
						) : null
					}}
				/>
			</Box>
		</>
	);
};

const ConfiguratorQprofile = ({ name, value, form, element }) => {
	const { options } = element;
	const values = value.split(' ');
	const [regValue, setRegValue] = useState(values);
	const [valuesPairs, setValuesPairs] = useState([]);

	const setNewValuesPairs = () => {
		let pairs = [];

		for (const [index, rv] of regValue.entries()) {
			pairs.push({
				from: index === 0 ? '0' : regValue[index - 1],
				to: rv
			});
		}

		setValuesPairs(pairs);
	};

	const handleOnChange = (index, newValue) => {
		const newRegValue = regValue;

		newRegValue[index] = newValue;
		setRegValue(newRegValue);
		setNewValuesPairs();

		form.setFieldValue(name, newRegValue.join(' '));
	};

	React.useEffect(() => {
		setNewValuesPairs();
	}, []);

	if (valuesPairs.length === 0) return null;

	return options.map((option, index) => {
		return (
			<ConfiguratorQprofileSingle
				key={index}
				index={index}
				{...option}
				valuesPair={valuesPairs[index]}
				onChange={handleOnChange}
			/>
		);
	});
};

export default ConfiguratorQprofile;
