import { useState } from 'react';
import { Collapse } from '@mui/material';
import { BaseDatePicker } from '../..';

const DateRangeOwn = ({ show, onChange, initialOwnValue = null }) => {
	const [open, setOpen] = useState(false);

	const handleChange = (date) => {
		onChange(date);
		setOpen(false);
	};

	return (
		<Collapse in={show}>
			<BaseDatePicker
				open={open}
				onOpen={() => setOpen(true)}
				label={'Stats_DatetimeRangeFrom'}
				disableFuture
				variant='inline'
				fullWidth
				value={initialOwnValue}
				onChange={handleChange}
				onClose={() => setOpen(false)}
			/>
		</Collapse>
	);
};

export default DateRangeOwn;
