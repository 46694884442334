import React from 'react';
import { Avatar, Box, Fade, ListItemAvatar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WarningIcon from '../../icons/WarningIcon';
import BaseButtonOnlyIcon from '../Buttons/BaseButtonOnlyIcon';
import ButtonOnlyIconSubmit from '../Buttons/ButtonOnlyIconSubmit';
import { Spacer, TableListColumn, VerticalSpacer } from '..';

const useStyles = makeStyles((theme) => ({
	primaryTextRoot: {
		color: theme.palette.common.white
	},
	secondaryTextRoot: {
		color: theme.palette.common.white
	},
	avatarRoot: {
		color: theme.palette.common.white
	},
	action: {
		color: theme.palette.common.white,
		backgroundColor: '#BC0A29',
		'&:hover': {
			backgroundColor: '#cb0b2b'
		}
	},
	progress: {
		color: theme.palette.common.white
	}
}));

const TableListItemDelete = ({
	deleteMode,
	setDeleteMode,
	pending,
	onDelete,
	primaryText,
	secondaryText,
	spacer = true,
	children
}) => {
	const classes = useStyles();

	const handleCancel = (e) => {
		e.stopPropagation();
		e.preventDefault();

		setDeleteMode(false);
	};

	const handleDelete = (e) => {
		e.stopPropagation();
		e.preventDefault();
		onDelete();
	};

	return (
		<Fade in={deleteMode}>
			<Box display='flex' alignItems='center' width='100%'>
				<ListItemAvatar>
					<Avatar classes={{ root: classes.avatarRoot }}>
						<WarningIcon fontSize='s' />
					</Avatar>
				</ListItemAvatar>
				<TableListColumn flex>
					{children || (
						<Box display='flex' flexDirection='column'>
							<Typography
								variant='body2'
								classes={{
									root: classes.primaryTextRoot
								}}>
								{primaryText}
							</Typography>
							<Typography
								variant='caption'
								classes={{
									root: classes.secondaryTextRoot
								}}>
								{secondaryText}
							</Typography>
						</Box>
					)}
				</TableListColumn>
				{spacer && <Spacer />}
				<TableListColumn
					width={80}
					disableLeftPadding
					disableRightPadding
					display='flex'
					justifyContent='flex-end'>
					<BaseButtonOnlyIcon
						icon={ArrowBackIcon}
						pending={pending}
						name='tableListItemDeleteCancel'
						className={classes.action}
						onClick={handleCancel}
					/>
					<VerticalSpacer size={1} />
					<ButtonOnlyIconSubmit
						progress
						progressProps={{ className: classes.progress }}
						pending={pending}
						name='tableListItemDeleteSubmit'
						className={classes.action}
						onClick={handleDelete}
					/>
				</TableListColumn>
			</Box>
		</Fade>
	);
};

export default TableListItemDelete;
