import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '@sonar-web/common/src/hooks';
import RssiAfcData from './RssiAfcData';

const useStyles = makeStyles((theme) => ({
	secondaryText: {
		color: theme.palette.text.secondary,
		verticalAlign: 'middle',
		whiteSpace: 'nowrap'
	},
	primaryText: {
		color: theme.palette.text.primary,
		verticalAlign: 'middle',
		whiteSpace: 'nowrap'
	}
}));

const TerminalTransmissionData = ({ row }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	const [transmissionData, setTransmissionData] = useState(null);

	useEffect(() => {
		if (row.transmissionData) {
			const parsedData = JSON.parse(row.transmissionData).sort((a, b) => a.Order - b.Order);

			let data = [];

			for (let i = 0; i < parsedData.length; i++) {
				let item = {};

				if (i == 0) {
					item.deviceLabel = translate('Devices_Device');
				} else {
					item.deviceLabel = `${translate('Devices_Retransmitter')} (${parsedData[i - 1].RaField})`;
				}

				item.rssi = parsedData[i].Rssi;
				item.afc = parsedData[i].Afc;

				data.push(item);
			}
			data.push({
				deviceLabel: translate('Devices_Concentrator')
			});
			setTransmissionData(data);
		}
	}, [row]);

	return (
		<Box display='flex' flexDirection='row' flexWrap='wrap'>
			{!transmissionData || transmissionData.length == 0 ? (
				<Typography
					variant='body2'
					classes={{
						root: classes.secondaryText
					}}>
					{translate('Devices_DeviceNoTramsissionDataText')}
				</Typography>
			) : (
				<>
					{transmissionData?.map((item, index) => {
						return (
							<Box display='flex' flexDirection='row' key={index + 1}>
								{item.deviceLabel && (
									<Typography
										variant='body2'
										classes={{
											root: classes.primaryText
										}}>
										{translate(item.deviceLabel)}
									</Typography>
								)}
								{item.rssi == undefined && item.afc == undefined ? null : (
									<RssiAfcData rssi={item.rssi} afc={item.afc} />
								)}
							</Box>
						);
					})}
				</>
			)}
		</Box>
	);
};

export default TerminalTransmissionData;
