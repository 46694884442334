import { ROUTE_GUID } from '@sonar-web/common';

export const moduleName = 'billing';

const slices = {
	billingPeriods: 'billingPeriods',
	billingPeriod: 'billingPeriod',
	billings: 'billings',
	billing: 'billing',
	billingClient: 'billingClient',
	billingClients: 'billingClients'
};

const routes = {
	clients: () => {
		return `/billingclients`;
	},
	client: (props = {}) => {
		const { action, clientId, withQueryParam } = props;

		if (action === 'edit')
			return clientId ? `/billingclient/${clientId}/edit` : `/billingclient/:clientId(${ROUTE_GUID})/edit`;

		if (withQueryParam === true) return `/billingclient/:tenant`;
		if (withQueryParam != null) return `/billingclient/${withQueryParam}`;

		return `/billingclient`;
	},
	billings: (props = {}) => {
		const { clientId, billingType } = props;
		return clientId
			? `/billingclient/${clientId}/billings/${billingType}`
			: `/billingclient/:clientId(${ROUTE_GUID})/billings/:billingType`;
	},
	billing: (props = {}) => {
		const { action, clientId, billingId, billingType } = props;

		if (action === 'edit')
			return billingId
				? `/billingclient/${clientId}/billing/${billingType}/${billingId}/edit`
				: `/billingclient/:clientId(${ROUTE_GUID})/billing/:billingType/:billingId(${ROUTE_GUID})/edit`;

		if (action === 'dashboard')
			return billingId
				? `/billingclient/${clientId}/billing/${billingType}/${billingId}`
				: `/billingclient/:clientId(${ROUTE_GUID})/billing/:billingType/:billingId(${ROUTE_GUID})`;

		return clientId
			? `/billingclient/${clientId}/billing/${billingType}`
			: `/billingclient/:clientId(${ROUTE_GUID})/billing/:billingType`;
	}
};

const Module = {
	moduleName,
	slices,
	routes
};

export default Module;
