import { useEffect, useState } from 'react';
import { PanelContentWrapper } from '@sonar-web/common';
import useList from '@sonar-web/common/src/features/List/useList';
import EventBus from '@sonar-web/common/src/helpers/EventBus';
import WatermeterReadsListLocations from './WatermeterReadsListLocations';
import { slices } from '../Constants/Module';
import ReadsUpdater from './ReadsUpdater';
import Messages from '../Constants/Messages';

const slice = slices.watermeterReads;

const WatermeterReadsLocationPage = ({ location }) => {
	const { resetList, removeSorters } = useList(slice);
	const [resetKey, setResetKey] = useState(0);

	useEffect(() => {
		const onManualReadAdded = () => {
			removeSorters();
			setResetKey((prev) => prev + 1);
		};

		EventBus.on(Messages.ManualReadAdded, onManualReadAdded);

		return () => {
			resetList(false);
			removeSorters();
			EventBus.off(Messages.ManualReadAdded, onManualReadAdded);
		};
	}, []);

	return (
		<PanelContentWrapper
			key={location.id + resetKey}
			hasCover
			mainContent={
				<>
					<ReadsUpdater />
					<WatermeterReadsListLocations location={location} />
				</>
			}
		/>
	);
};

export default WatermeterReadsLocationPage;
