import { WaterMeterIcon, HeatAllocatorIcon, WmbusTerminalIcon, YupSchemaDefinitions } from '@sonar-web/common';
import TcpDevicesIcon from '@sonar-web/common/src/icons/TcpDevicesIcon';
import WmbusDevicesIcon from '@sonar-web/common/src/icons/WmbusDevicesIcon';
import HeatAllocatorMountedIcon from '@sonar-web/common/src/icons/HeatAllocatorMountedIcon';
import HeatAllocatorUnmountedIcon from '@sonar-web/common/src/icons/HeatAllocatorUnmountedIcon';
import WaterMeterMountedIcon from '@sonar-web/common/src/icons/WaterMeterMountedIcon';
import WaterMeterUnmountedIcon from '@sonar-web/common/src/icons/WaterMeterUnmountedIcon';
import HeatMeterIcon from '@sonar-web/common/src/icons/HeatMeterIcon';

export const SLICES = {
	setWizard: 'setWizard',
	sets: 'sets'
};

export const DEVICE_KINDS = {
	transmission: 'Transmission',
	meter: 'Measurement',
	other: 'Other'
};

export const GROUP_TYPES = {
	WaterMeters: {
		name: 'WaterMeters',
		deviceSelect: {
			startReadValue: {
				validationShape: (translate) => YupSchemaDefinitions.initialStateDecimalNegative(translate),
				label: (translate) => `${translate('MeasurementSet_InitialState')} [${translate('MeasurementSet_m3')}]`
			},
			endReadValue: {
				validationShape: (translate) => YupSchemaDefinitions.endStateDecimalNegative(translate),
				label: (translate) => `${translate('MeasurementSet_EndState')} [${translate('MeasurementSet_m3')}]`
			}
		}
	},
	WaterMeterTransmissionDevices: {
		name: 'WaterMeterTransmissionDevices',
		deviceSelect: {
			startReadValue: {
				validationShape: (translate) => YupSchemaDefinitions.initialStateDecimalNegative(translate),
				label: (translate) => `${translate('MeasurementSet_InitialState')} [${translate('MeasurementSet_m3')}]`
			},
			endReadValue: {
				validationShape: (translate) => YupSchemaDefinitions.endStateDecimalNegative(translate),
				label: (translate) => `${translate('MeasurementSet_EndState')} [${translate('MeasurementSet_m3')}]`
			}
		}
	},
	HeatCostAllocators: {
		name: 'HeatCostAllocators',
		deviceSelect: {
			startReadValue: {
				validationShape: (translate) => YupSchemaDefinitions.initialStateInt(translate),
				label: (translate) => translate('MeasurementSet_InitialState')
			},
			endReadValue: {
				validationShape: (translate) => YupSchemaDefinitions.endStateInt(translate),
				label: (translate) => translate('MeasurementSet_EndState')
			}
		}
	},
	HeatMeters: {
		name: 'HeatMeters',
		deviceSelect: {
			startReadValue: {
				validationShape: (translate) => YupSchemaDefinitions.initialStateDecimal(translate),
				label: (translate) => `${translate('HeatMeterInitialStateHeat')} [${translate('GJ')}]`
			},
			secondStartReadValue: {
				validationShape: (translate) => YupSchemaDefinitions.initialStateDecimal(translate),
				label: (translate) => `${translate('HeatMeterInitialStateCold')} [${translate('GJ')}]`
			},
			endReadValue: {
				validationShape: (translate) => YupSchemaDefinitions.endStateDecimal(translate),
				label: (translate) => `${translate('HeatMeterEndStateHeat')} [${translate('GJ')}]`
			},
			secondEndReadValue: {
				validationShape: (translate) => YupSchemaDefinitions.endStateDecimal(translate),
				label: (translate) => `${translate('HeatMeterEndStateCold')} [${translate('GJ')}]`
			}
		}
	}
};

export const modules = {
	tcp: {
		name: 'tcp',
		devicesRoute: 'tcp'
	},
	wmbus: {
		name: 'wmbus',
		devicesRoute: 'wmbusdevices'
	},
	watermeter: {
		name: 'watermeter',
		devicesRoute: 'watermeters'
	},
	heatallocator: {
		name: 'heatallocator',
		devicesRoute: 'heatallocators'
	},
	heatmeter: {
		name: 'heatmeter',
		devicesRoute: 'heatmeter'
	},
	mesh: {
		name: 'mesh',
		devicesRoute: 'meshterminals'
	},
	meshterminal: {
		name: 'meshterminal',
		devicesRoute: 'meshterminals'
	},
	other: {
		name: 'other',
		devicesRoute: null
	}
};

const defaultFontSize = 'xs';
export const GROUP_TYPE_NAME = [
	{
		name: 'HeatCostAllocators',
		getIcon: (fontSize) => <HeatAllocatorIcon fontSize={fontSize ?? defaultFontSize} />,
		getIconMounted: (fontSize, props = {}) => (
			<HeatAllocatorMountedIcon fontSize={fontSize ?? defaultFontSize} {...props} />
		),
		getIconUnmounted: (fontSize, props = {}) => (
			<HeatAllocatorUnmountedIcon fontSize={fontSize ?? defaultFontSize} {...props} />
		),
		type: DEVICE_KINDS.meter
	},
	{
		name: 'WaterMeters',
		getIcon: (fontSize) => <WaterMeterIcon fontSize={fontSize ?? defaultFontSize} />,
		getIconMounted: (fontSize, props = {}) => (
			<WaterMeterMountedIcon fontSize={fontSize ?? defaultFontSize} {...props} />
		),
		getIconUnmounted: (fontSize, props = {}) => (
			<WaterMeterUnmountedIcon fontSize={fontSize ?? defaultFontSize} {...props} />
		),
		type: DEVICE_KINDS.meter
	},
	{
		name: 'Tcp',
		getIcon: (fontSize) => <TcpDevicesIcon fontSize={fontSize ?? defaultFontSize} />,
		type: DEVICE_KINDS.transmission
	},
	{
		name: 'WaterMeterTransmissionDevices',
		getIcon: (fontSize) => <WmbusDevicesIcon fontSize={fontSize ?? defaultFontSize} />,
		type: DEVICE_KINDS.transmission
	},
	{
		name: 'WmbusRetransmiters',
		getIcon: (fontSize) => <WmbusTerminalIcon fontSize={fontSize ?? defaultFontSize} />,
		type: DEVICE_KINDS.other
	},
	{
		name: 'GasMeterTransmissionDevices',
		getIcon: (fontSize) => <WmbusTerminalIcon fontSize={fontSize ?? defaultFontSize} />,
		type: DEVICE_KINDS.other
	},
	{
		name: 'HeatMeterTransmissionDevices',
		getIcon: (fontSize) => <WmbusTerminalIcon fontSize={fontSize ?? defaultFontSize} />,
		type: DEVICE_KINDS.other
	},
	{
		name: 'HeatMeters',
		getIcon: (fontSize) => <HeatMeterIcon fontSize={fontSize ?? defaultFontSize} />,
		type: DEVICE_KINDS.meter
	},
	{
		name: 'GasMeters',
		getIcon: (fontSize) => <WmbusTerminalIcon fontSize={fontSize ?? defaultFontSize} />,
		type: DEVICE_KINDS.other
	},
	{
		name: 'Other',
		getIcon: (fontSize) => <WmbusTerminalIcon fontSize={fontSize ?? defaultFontSize} />,
		type: DEVICE_KINDS.other
	}
];

export const SET_WIZARD_MODE = {
	add: 'add',
	edit: 'edit',
	view: 'view',
	close: 'close',
	delete: 'delete'
};
