import DOMPurify from 'dompurify';

function getValue(name) {
	const value = window.localStorage.getItem(name);

	if (value == null) return null;
	return DOMPurify.sanitize(value);
}

function setValue(name, value) {
	window.localStorage.setItem(name, value);
}

const LocalStorageHelper = {
	getValue,
	setValue
};

export default LocalStorageHelper;
