import React from 'react';
import { InputAdornment, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '../../../../hooks';

const useStyles = makeStyles(() => ({
	startAdornmentRoot: {
		marginBottom: 4
	},
	adormentPositionStart: {
		marginRight: 4
	}
}));

const ConfiguratorPhonefield = ({ onChange, onBlur, name, value, error, helperText, dataAtError }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	return (
		<TextField
			type='text'
			margin='normal'
			size='small'
			fullWidth
			label={translate(name)}
			name={name}
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			error={error}
			helperText={helperText}
			classes={{ root: classes.root }}
			FormHelperTextProps={{
				'data-at-error': dataAtError
			}}
			InputProps={{
				startAdornment: (
					<InputAdornment position='start' classes={{ positionStart: classes.adormentPositionStart }}>
						<Typography
							variant='body2'
							color='textSecondary'
							classes={{ root: classes.startAdornmentRoot }}>
							+
						</Typography>
					</InputAdornment>
				)
			}}
		/>
	);
};

export default ConfiguratorPhonefield;
