import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';
import { AuthService } from '@sonar/auth';

const path = REST_API_PATHS.ACCESS;

async function fetchUsers(pageDescriptor) {
	const { data } = await apiBase(`users`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}

async function deleteUser(id) {
	return apiBase(`users/${id}`, 'delete', {
		path
	});
}

async function fetchGroups(pageDescriptor) {
	const clientId = AuthService.getClientId();

	const { data } = await apiBase(`clients/${clientId}/groups`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return data;
}

async function deleteGroup(id) {
	return apiBase(`groups/${id}`, 'delete', {
		path
	});
}

async function fetchRoles() {
	const clientId = AuthService.getClientId();
	const { data } = await apiBase(`clients/${clientId}/tenantRoles`, 'get', {
		path
	});

	return data;
}

async function checkEmail(email) {
	const clientId = AuthService.getClientId();
	const { data } = await apiBase(`users/checkEmail`, 'get', {
		path,
		params: { email, clientId }
	});

	return data;
}

async function assignUserToTenant(userData) {
	const clientId = AuthService.getClientId();
	const { data } = await apiBase(`clients/${clientId}/users/${userData.id}/assignToTenant`, 'put', {
		path,
		data: {
			roles: userData.clientRoles,
			clientId,
			userId: userData.id,
			firstName: userData.firstName,
			lastName: userData.lastName
		}
	});

	return data;
}

async function unassignUserFromTenant(userId) {
	const clientId = AuthService.getClientId();
	const { data } = await apiBase(`clients/${clientId}/users/${userId}/unassignFromTenant`, 'put', {
		path,
		data: { clientId, userId: userId }
	});

	return data;
}

async function synchronizationStatus() {
	const { data } = await apiBase(`users/synchronize/status`, 'get', {
		path
	});

	return data;
}

async function synchronize() {
	const { data } = await apiBase(`users/synchronize`, 'post', {
		path,
		data: {}
	});

	return data;
}

async function fetchUsersRoles(pageDescriptor, config) {
	const { data } = await apiBase(`users/roles`, 'get', {
		path,
		params: { pageDescriptor },
		...config
	});

	return data;
}

async function addContractorAdmin(requestData) {
	const response = await apiBase(`contractorUsers`, 'post', {
		path,
		data: requestData
	});

	const locationHeader = response.headers.location;
	if (locationHeader) return locationHeader.slice(locationHeader.lastIndexOf('/') + 1);

	return response.data;
}

async function fetchUsersWithRoles({
	withAnyOfRoles,
	withoutAnyOfUserIds,
	offset,
	limit,
	search = '',
	orderProperty,
	orderDirection
}) {
	const encodedWithAnyOfRoles = encodeURIComponent(JSON.stringify(withAnyOfRoles));
	const encodedWithoutAnyOfUserIds = encodeURIComponent(JSON.stringify(withoutAnyOfUserIds));
	const encodedSearch = encodeURIComponent(search);
	const queryString = `search=${encodedSearch}&withAnyOfRoles=${encodedWithAnyOfRoles}&withoutAnyOfUserIds=${encodedWithoutAnyOfUserIds}&offset=${offset}&limit=${limit}&orderProperty=${orderProperty}&orderDirection=${orderDirection}`;

	const { data } = await apiBase(`userswithroles?${queryString}`, 'get', {
		path
	});

	return data;
}

const AccessService = {
	fetchUsers,
	deleteUser,
	fetchGroups,
	deleteGroup,
	fetchRoles,
	checkEmail,
	assignUserToTenant,
	unassignUserFromTenant,
	synchronizationStatus,
	synchronize,
	fetchUsersRoles,
	addContractorAdmin,
	fetchUsersWithRoles
};

export default AccessService;
