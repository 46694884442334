import useLocale from '@sonar-web/common/src/hooks/useLocale';
import { AuthService } from '@sonar/auth';
import ButtonAdd from '@sonar-web/common/src/components/Buttons/ButtonAdd';

const Logout = () => {
	const { translate } = useLocale();

	return (
		<ButtonAdd onClick={AuthService.doLogout} startIcon={null}>
			{translate('Logout')}
		</ButtonAdd>
	);
};

export default Logout;
