import { lazy } from 'react';
import { ROUTE_GUID } from '@sonar-web/common';
import { Features } from '@sonar/auth';

const DevicePage = lazy(() => import('./Device/DevicePage'));

export default [
	{
		component: DevicePage,
		feature: Features.DevicePage.name,
		path: `/wmbusdevices/:id(${ROUTE_GUID})`,
		exact: true,
		name: '{0}'
	}
];
