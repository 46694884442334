import { lazy } from 'react';
import { Features } from '@sonar/auth';
import { routes } from './Constants/Module';

const OrdersAndOrdersPacks = lazy(() => import('./OrdersAndOrdersPacks'));
const OrderNew = lazy(() => import('./Order/OrderNew'));
const OrderCopy = lazy(() => import('./Order/OrderCopy'));
const OrderPage = lazy(() => import('./Order/OrderPage'));
const ProtocolTemplate = lazy(() => import('./ProtocolTemplate/ProtocolTemplatePage'));
const OrdersPackPage = lazy(() => import('./OrdersPack/OrdersPackPage'));
const OrdersPackPageSimple = lazy(() => import('./OrdersPack/OrdersPackPageSimple'));

export default [
	{
		component: OrdersAndOrdersPacks,
		feature: Features.OrdersPage.name,
		path: routes.currentOrders(),
		exact: true,
		name: 'Orders_Orders'
	},
	{
		component: OrdersAndOrdersPacks,
		feature: Features.OrdersPage.name,
		path: routes.archivedOrders(),
		exact: true,
		name: 'Orders_ArchivedOrders'
	},
	{
		component: OrderNew,
		feature: Features.OrderAdd.name,
		path: routes.orderNew(),
		exact: true,
		name: 'Orders_NewOrder'
	},
	{
		component: OrderCopy,
		feature: Features.OrderAdd.name,
		path: routes.orderCopy(),
		exact: true,
		name: 'Orders_NewOrder'
	},
	{
		component: OrderPage,
		feature: Features.OrderPage.name,
		path: routes.orderPage(),
		exact: true,
		name: 'OrderPage'
	},
	{
		component: ProtocolTemplate,
		feature: Features.ProtocolTemplatePage.name,
		path: routes.protocolTemplate(),
		exact: true,
		name: 'Orders_ProtocolTemplate'
	},
	{
		component: OrdersAndOrdersPacks,
		feature: Features.ContractorsPage.name,
		path: routes.contractors(),
		exact: true,
		name: 'Contractors'
	},
	{
		component: OrdersAndOrdersPacks,
		feature: Features.OrderedOrdersPacks.name,
		path: routes.orderedOrdersPacks(),
		exact: true,
		name: 'OrderedOrdersPacks'
	},
	{
		component: OrdersAndOrdersPacks,
		feature: Features.ReceivedOrdersPacks.name,
		path: routes.receivedOrdersPacks(),
		exact: true,
		name: 'ReceivedOrdersPacks'
	},
	{
		component: OrdersPackPage,
		feature: Features.OrdersPackPage.name,
		path: routes.ordersPackPage(),
		exact: true,
		name: 'AgrrementPage'
	},
	{
		component: OrdersPackPageSimple,
		feature: Features.OrdersPackPageSimple.name,
		path: routes.ordersPackPageSimple(),
		exact: true,
		name: 'OrdersPackPageSimple'
	}
];
