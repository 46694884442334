import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { commonRouting } from '@sonar-web/common';
import { useLocale, useFormat } from '@sonar-web/common/src/hooks';
import LinkRouter from './LinkRouter';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(2)
	},
	linkLast: {
		fontWeight: theme.typography.fontWeightMedium
	},
	li: {
		'& a': {
			textDecoration: 'none'
		}
	}
}));

export default function RouterBreadcrumbs({ routes, lastRouteName }) {
	const classes = useStyles();
	const location = useLocation();
	const { translate } = useLocale();
	const { format } = useFormat();
	const combinedRoutes = [
		{
			path: '/',
			exact: true,
			name: 'Common_DashboardPage',
			standalone: true
		},
		...commonRouting,
		...routes
	];
	const patch = location.pathname;

	let pathnames = patch.split('/').filter((x) => x);
	pathnames = pathnames[pathnames.length - 1] === '' ? pathnames.slice(0, pathnames.length - 1) : pathnames;
	pathnames.splice(0, 0, '/');

	return (
		<Breadcrumbs
			maxItems={9}
			separator='›'
			aria-label='breadcrumb'
			classes={{ root: classes.root, li: classes.li }}>
			{pathnames.map((value, index) => {
				const last = index === pathnames.length - 1;
				const to = index > 0 ? `/${pathnames.slice(1, index + 1).join('/')}` : '/';
				let routeName;

				if (last && lastRouteName) routeName = lastRouteName;
				else {
					const currentRoute = combinedRoutes.find((route) => matchPath(to, route));

					if (currentRoute) routeName = format(translate(currentRoute.name), value);
				}

				if (!routeName) return null;
				return last ? (
					<Typography
						variant='body2'
						component='a'
						color='textPrimary'
						className={classes.linkLast}
						key={to}
						data-at={`lastRoute`}>
						{routeName}
					</Typography>
				) : (
					<LinkRouter
						color='textSecondary'
						to={to}
						key={to}
						data-at={`route_${value == '/' ? 'mainPage' : value}`}>
						{routeName}
					</LinkRouter>
				);
			})}
		</Breadcrumbs>
	);
}
