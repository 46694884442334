import { lazy } from 'react';
import { Features } from '@sonar/auth';
import { routes } from './Constants/Module';

const DevicesTypesPage = lazy(() => import('./DevicesTypes/DevicesTypesPage'));
const DevicesConfigurationsPage = lazy(() => import('./DevicesConfigurations/DevicesConfigurationsPage'));
const DeviceConfigurationCreatorPage = lazy(() =>
	import('./DeviceConfigurationCreator/DeviceConfigurationCreatorPage')
);
const WizardActions = lazy(() => import('./DeviceConfigurationCreator/WizardActions'));

export default [
	{
		component: DevicesTypesPage,
		feature: Features.DeviceConfigurationsPage.name,
		path: routes.configurations(),
		exact: true,
		name: 'Dconf_DeviceTypesForConfiguration'
	},
	{
		component: DevicesConfigurationsPage,
		feature: Features.DeviceConfigurationsPage.name,
		path: routes.configurationsList(),
		exact: true,
		name: 'Dconf_DevicesConfigurations'
	},
	{
		component: DeviceConfigurationCreatorPage,
		feature: Features.DeviceConfigurationPage.name,
		path: routes.configurationWizard(),
		exact: true,
		name: 'Dconf_DeviceConfigurationCreator'
	},
	{
		component: WizardActions,
		feature: Features.DeviceConfigurationPage.name,
		path: routes.configurationWizardAction(),
		exact: true,
		name: '{0}'
	}
];
