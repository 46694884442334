import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { Box, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BaseLinearProgress } from '@sonar-web/common';
import { usePrevious } from '@sonar-web/common/src/hooks';
import TimelineOneDayPeriod from './TimelineOneDayPeriod';
import TimelineLastPoint from './TimelineLastPoint';
import { selectConverterDiagnosticReads } from './converterDiagnosticReadsTimelineSlice';
import DatePeriodPager from './DatePeriodPager';
import TimelineLegend from './TimelineLegend';
import { addDays, groupReadsByPeriod } from './helpers';
import ConverterStatus from '../ConverterStatus';

const useStyles = makeStyles((theme) => ({
	arrowRight: {
		width: 0,
		height: 0,
		borderTop: '5px solid transparent',
		borderBottom: '5px solid transparent',
		borderLeft: `10px solid ${theme.palette.grey[300]}`,
		color: theme.palette.grey[300],
		marginTop: 16
	},
	arrowWrapper: {
		display: 'flex'
	}
}));

const groupReadsByDay = (readsItems, range) => {
	const now = new Date();
	const oneDayPeriodInMs = addDays(now, 1).getTime() - now.getTime();
	return groupReadsByPeriod(readsItems, range, oneDayPeriodInMs);
};

const DeviceDiagnosticReadsTimeline = ({ deviceId }) => {
	const classes = useStyles();
	const theme = useTheme();
	const [range, setRange] = useState(null);
	const [isNewestRange, setIsNewestRange] = useState(true);
	const previousRange = usePrevious(range);

	const { reads, lastEntry, pending } = useSelector(selectConverterDiagnosticReads);

	const previousReads = usePrevious(reads);
	const [periods, setPeriods] = useState([]);

	useEffect(() => {
		if (!range) {
			return;
		}

		setIsNewestRange(range.end.getTime() > new Date().getTime());

		if (pending) {
			return;
		}

		if (!reads) {
			return;
		}

		const noEqualRange = !isEqual(range, previousRange);
		const noEqualReads = !isEqual(reads, previousReads);
		if (noEqualRange || noEqualReads) {
			const readsItems = reads
				.map((r) => {
					return { ...r, date: new Date(Date.parse(r.date)) };
				})
				.filter((r) => r.status === ConverterStatus.Connected.name);

			const periods = groupReadsByDay(readsItems, range);

			setPeriods(periods);
		}
	}, [range, pending]);

	const PeriodDivicer = () => {
		return (
			<Box
				style={{
					height: 40,
					width: 1,
					background: theme.palette.grey[300]
				}}></Box>
		);
	};

	return (
		<>
			<DatePeriodPager deviceId={deviceId} range={range} setRange={setRange} />
			<TimelineLegend mt={1} mb={2} />
			<Box mt={2}>
				<BaseLinearProgress pending={pending} />
			</Box>
			{!pending && (
				<Box display='flex' flexDirection='column' mt={2}>
					<Box display='flex' flexDirection='row' flex={1} mb={12} alignItems='flex-start'>
						<>
							{periods.map((p, index) => (
								<Box display='flex' flexDirection='column' flexGrow={4} key={index}>
									<Box display='flex' flexDirection='row'>
										<PeriodDivicer />
										<TimelineOneDayPeriod reads={p.reads} range={p.range} />
									</Box>
								</Box>
							))}
							{isNewestRange && (
								<>
									<TimelineLastPoint lastEntry={lastEntry} />
									<Box className={classes.arrowWrapper}>
										<Box className={classes.arrowRight}></Box>
									</Box>
								</>
							)}
						</>
					</Box>
				</Box>
			)}
		</>
	);
};

export default DeviceDiagnosticReadsTimeline;
