import { pageDescriptor } from '@sonar-web/common';

export const readsMapStateName = 'readsMap';

export const readsMapInitialState = {
	pageDescriptor,
	fetchPending: false,
	fetchSuccess: false,
	elements: null
};

export const readsMapReducers = {
	setReadsMapFetchSucces: (state, { payload }) => {
		const elements = filterNullCoordinates(payload.elements);

		state[readsMapStateName].elements = elements;
		state[readsMapStateName].fetchPending = false;
		state[readsMapStateName].fetchSuccess = true;
	},
	setReadsMapFetchPending: (state, { payload }) => {
		state[readsMapStateName].pending = payload;
	},
	resetReadsMapFetchSuccess: (state) => {
		state[readsMapStateName].fetchSuccess = false;
	}
};

function filterNullCoordinates(data) {
	return data.filter((d) => d.coordinate?.length);
}
