import { createSelector, createSlice } from '@reduxjs/toolkit';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import AuthService from '@sonar/auth/src/AuthService';
import { editProfile, fetchProfile } from './userProfileApi';
import Module from '../Constants/Module';

const slice = Module.slices.userProfile;
const getClient = () => AuthService.getClientId();

const initialData = {
	id: null,
	firstName: '',
	lastName: '',
	profilename: '',
	email: '',
	password: '',
	changePassword: true,
	enabled: true,
	clientRoles: [],
	groups: []
};

export const userProfileSlice = createSlice({
	name: slice,
	initialState: {
		editPending: false,
		editSuccess: false,
		fetchPending: false,
		fetchSuccess: false,
		profile: initialData
	},
	reducers: {
		fetchProfileSuccess: (state, { payload }) => {
			state.profile = {
				...payload,
				email: payload.email ?? '',
				password: '',
				changePassword: false
			};
			state.fetchPending = false;
			state.fetchSuccess = true;
		},
		fetchProfilePending: (state, { payload }) => {
			state.fetchPending = payload;
			state.fetchSuccess = false;
		},
		editProfileSuccess: (state) => {
			state.editPending = false;
			state.editSuccess = true;
		},
		editProfilePending: (state, { payload }) => {
			state.editPending = payload;
		},
		setProfileData: (state, { payload }) => {
			state.profile = payload;
		},
		resetProfileData: (state) => {
			state.profile = initialData;
			state.editSuccess = false;
			state.fetchSuccess = false;
		}
	}
});

export const {
	fetchProfileSuccess,
	fetchProfilePending,
	editProfileSuccess,
	editProfilePending,
	setProfileData,
	resetProfileData
} = userProfileSlice.actions;

export const fetchProfileAsync = (id) => async (dispatch) => {
	let response;

	try {
		dispatch(fetchProfilePending(true));
		response = await fetchProfile(id, getClient());
		dispatch(fetchProfileSuccess(response));
	} catch (error) {
		dispatch(fetchProfilePending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const editProfileAsync = () => async (dispatch, getState) => {
	try {
		dispatch(editProfilePending(true));
		await editProfile({ ...getState().userProfile.profile, clientId: getClient() });
		dispatch(editProfileSuccess());
	} catch (error) {
		dispatch(editProfilePending(false));
		return dispatch(
			addErrorAsync({ slice, error, skipNotificationMembers: ['firstName', 'lastName', 'email', 'password'] })
		);
	}
};

export const selectProfile = createSelector(
	(state) => state.userProfile,
	(userProfile) => ({
		dataRow: userProfile.profile,
		editPending: userProfile.editPending,
		editSuccess: userProfile.editSuccess,
		fetchPending: userProfile.fetchPending,
		fetchSuccess: userProfile.fetchSuccess
	})
);

export default userProfileSlice.reducer;
