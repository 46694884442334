import React from 'react';
import * as Yup from 'yup';
import { apiBase } from '@sonar-web/common';
import SearchControlSimpleBase from '@sonar-web/common/src/components/SearchAutocomplete/SearchControlSimpleBase';
import ShareUsersListItem from './ShareUsersListItem';

const ShareUsersSelect = ({ servicePath, currentUsers, disableSearch, ...rest }) => {
	return (
		<SearchControlSimpleBase
			apiResultObjectname='users'
			label={disableSearch ? 'Common_InsertEmail' : 'Reports_InsertUserNameOrEmail'}
			slice='reports'
			api={(v) => fetch(servicePath, v, currentUsers)}
			disableSearch={disableSearch}
			displayValue='name'
			customRenderOption={(p) => (
				<ShareUsersListItem
					{...p.option}
					autocompleteProps={p.props}
					key={p.props.id}
					disableSearch={disableSearch}
				/>
			)}
			clearable={false}
			ownInput={true}
			newOptionName='email'
			setNewOptionObject={(email, options) => {
				if (options?.length > 0) return null;

				const emailSchema = Yup.object().shape({ email: Yup.string().email() });
				const res = emailSchema.isValidSync({ email });
				const existsAndIsAdded = currentUsers.find((cu) => cu.email === email);

				return res && !existsAndIsAdded
					? { email, enabled: null, firstName: null, id: null, lastName: null, name: '' }
					: null;
			}}
			{...rest}
		/>
	);
};

export default ShareUsersSelect;

async function fetch(servicePath, value, currentUsers) {
	const { data } = await apiBase(`users`, 'get', {
		path: servicePath,
		params: { value }
	});

	if (data?.users?.length > 0) {
		const selectedMails =
			currentUsers.length === 0
				? []
				: currentUsers.reduce((acc, user) => {
						acc.push(user.email);
						return acc;
				  }, []);

		const notSelectedUsers = data.users.filter((u) => !selectedMails.includes(u.email));

		return { users: notSelectedUsers, totalCount: notSelectedUsers.length };
	}

	return data;
}
