import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';
import ApiHelpers from '@sonar-web/common/src/helpers/ApiHelpers';

const path = REST_API_PATHS.ORDER;

async function fetchProtocolTemplate() {
	const { data } = await apiBase(`installationProtocolsTemplate`, 'get', {
		path
	});

	return data;
}

async function updateProtocolTemplate(values) {
	const { data } = await apiBase(`installationProtocolsTemplate`, 'put', {
		path,
		data: values
	});

	return data;
}

async function updateProtocolTemplateSingleValue(keyValue) {
	const { data } = await apiBase(`installationProtocolsTemplateSingleValue`, 'put', {
		path,
		data: { elements: [{ item1: keyValue.key, item2: keyValue.value }] }
	});

	return data;
}

async function protocolTemplateLoadDefaults(lang) {
	const { data } = await apiBase(`installationProtocolsTemplate/default`, 'put', {
		path,
		data: { lang }
	});

	return data;
}

async function fetchProtocolTemplatePreview() {
	const { data } = await apiBase(`pdf/preview`, 'get', {
		path: path
	});

	return data;
}

export async function fetchContractors() {
	const { data } = await apiBase(`contractors`, 'get', {
		path: path
	});

	return data;
}

async function addContractor(requestData) {
	const response = await apiBase(`contractor`, 'post', {
		path,
		data: requestData
	});

	const locationHeader = response.headers.location;
	if (locationHeader) return locationHeader.slice(locationHeader.indexOf('/') + 1);
	return response.data;
}

async function updateContractor(requestData) {
	const { data } = await apiBase(`contractor`, 'put', {
		path,
		data: requestData
	});

	return data;
}

async function deleteContractor(contractorId) {
	const { data } = await apiBase(`contractor/${contractorId}`, 'delete', {
		path
	});

	return data;
}

async function addOrdersPack(requestData) {
	const { data } = await apiBase(`ordersPack`, 'post', {
		path,
		data: requestData
	});

	return data;
}

async function updateOrdersPack(requestData) {
	const { data } = await apiBase(`ordersPack`, 'put', {
		path,
		data: requestData
	});

	return data;
}

export async function deleteOrdersPack(ordersPackId) {
	const { data } = await apiBase(`ordersPack/${ordersPackId}`, 'delete', {
		path
	});

	return data;
}

async function fetchOrdersPack(ordersPackId) {
	const { data } = await apiBase(`ordersPack/${ordersPackId}`, 'get', {
		path
	});

	return data;
}

export async function fetchOrdersPacks(pageDescriptor, { ordersPackId }) {
	const params = ApiHelpers.urlParamsFromPageDescriptor(pageDescriptor, ['name', 'contractorName']);
	let queryParams = `ordersPack?${params}`;
	if (ordersPackId) queryParams += `&ordersPackId=${ordersPackId}`;

	const { data } = await apiBase(queryParams, 'get', {
		path
	});

	return data;
}

export async function searchContractors(pageDescriptor) {
	const params = ApiHelpers.urlParamsFromPageDescriptor(pageDescriptor);
	const { data } = await apiBase(`contractors/simple?${params}`, 'get', {
		path
	});

	return { elements: data, totalCount: data?.length };
}

async function checkIsContractor() {
	const { data } = await apiBase(`contractors/checkIsContractor`, 'get', {
		path
	});

	return data;
}

async function fetchProtocolsAndPhotos({ locationId, isMeasurementPoint }) {
	const { data } = await apiBase(`location/${locationId}/file`, 'get', {
		path,
		params: { isMeasurementPoint }
	});

	return data;
}

async function downloadProtocolFile(pathToFile) {
	const { data } = await apiBase(`file`, 'get', {
		path,
		params: { path: pathToFile }
	});

	return data;
}

export async function fetchAvailableOrders(pageDescriptor) {
	const { data } = await apiBase(`orders`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return { ...data, elements: data.orderWithStatistics };
}

export async function fetchOrdersInPack(pageDescriptor) {
	const { data } = await apiBase('orders', 'get', {
		path,
		params: { pageDescriptor }
	});

	return { ...data, elements: data.orderWithStatistics };
}

export async function fetchCurrentOrdersInPack(pageDescriptor, config) {
	const { data } = await apiBase(`ordersPack/${config.ordersPackId}/orders`, 'get', {
		path,
		params: { pageDescriptor }
	});

	return { ...data, elements: data.orderWithStatistics };
}

export async function fetchOrder(id) {
	const { data } = await apiBase(`order/${id}`, 'get', {
		path
	});

	return data;
}

export async function manageOrderFitters({ orderId, userIds }) {
	const { data } = await apiBase(`order/${orderId}/fitter `, 'put', {
		path,
		data: { userIds }
	});

	return data;
}

export async function cancelOrder(orderId) {
	const { data } = await apiBase(`order/${orderId}/cancel `, 'put', {
		path,
		data: {}
	});

	return data;
}

export async function unfulfillOrder(orderId) {
	const { data } = await apiBase(`order/${orderId}/unfulfill `, 'put', {
		path,
		data: {}
	});

	return data;
}

export async function fetchNote(orderId) {
	const { data } = await apiBase(`order/${orderId}/note`, 'get', {
		path
	});

	return data;
}

export async function addNote(requestData) {
	const response = await apiBase(`note`, 'post', {
		path,
		data: requestData
	});

	const locationHeader = response.headers.location;

	if (locationHeader) return locationHeader.slice(locationHeader.indexOf('/') + 1);
	return null;
}

export async function editNote(requestData) {
	const { data } = await apiBase(`note/${requestData.id}`, 'put', {
		path,
		data: requestData
	});

	return data;
}

export async function deleteNote(noteId) {
	const { data } = await apiBase(`note/${noteId}`, 'delete', {
		path
	});

	return data;
}

async function fetchDetailedInventoryFile(ordersPackId) {
	const { data } = await apiBase(`OrdersPack/${ordersPackId}/detailedSummary`, 'get', {
		path,
		responseType: 'blob'
	});

	return data;
}

async function fetchAggregatedInventoryFile(ordersPackId) {
	const { data } = await apiBase(`OrdersPack/${ordersPackId}/agregatedSummary`, 'get', {
		path,
		responseType: 'blob'
	});

	return data;
}

export async function assignFittersToOrders(requestData) {
	const { data } = await apiBase(`orders/fitters `, 'put', {
		path,
		data: requestData
	});

	return data;
}

async function fetchTags({ tag, limit, offset, withoutTagsIds }) {
	const encodedWithoutTagsIds = encodeURIComponent(JSON.stringify(withoutTagsIds));
	const encodedTag = encodeURIComponent(tag);
	const queryString = `tag=${encodedTag}&withoutTagsIds=${encodedWithoutTagsIds}&offset=${offset}&limit=${limit}`;

	const { data } = await apiBase(`tags?${queryString}`, 'get', {
		path
	});

	return data;
}

export async function addTagToOrder(requestData) {
	const { data } = await apiBase(`tag/assign`, 'put', {
		path,
		data: { orderId: requestData.orderId, tagId: requestData.tag.id }
	});

	return data;
}

export async function removeTagFromOrder(requestData) {
	const { data } = await apiBase(`tag/unassign`, 'put', {
		path,
		data: requestData
	});

	return data;
}

export async function addTag(requestData) {
	const response = await apiBase(`tag`, 'post', {
		path,
		data: { tag: requestData }
	});

	const locationHeader = response.headers.location;
	if (locationHeader) return { id: locationHeader.slice(locationHeader.indexOf('/') + 1), tag: requestData };
	return null;
}

async function fetchOrdersStatistics() {
	const { data } = await apiBase(`order/OrderStatuses`, 'get', {
		path
	});

	return data?.ordersStatistics || [];
}

export async function archiveOrder(orderId) {
	const { data } = await apiBase(`Order/${orderId}/Archive`, 'put', {
		path,
		data: {}
	});

	return data;
}

async function checkOrderCurrentOwnership({ orderId, ordersPackId }) {
	const { data } = await apiBase(`checkOrderCurrentOwnership/${ordersPackId}/${orderId}`, 'get', {
		path
	});

	return data;
}

export async function addOrder(values) {
	const response = await apiBase(`Order`, 'post', {
		path,
		data: values
	});

	const locationHeader = response.headers.location;
	if (locationHeader) return locationHeader.slice(locationHeader.lastIndexOf('/') + 1);
	return response;
}

export async function shareProtocol({ protocolId, emails }) {
	const { data } = await apiBase(`installationProtocol/${protocolId}/Send`, 'post', {
		path,
		data: { emails, sendToCustomer: false }
	});

	return data;
}

const OrdersService = {
	fetchProtocolTemplate,
	updateProtocolTemplate,
	fetchProtocolTemplatePreview,
	updateProtocolTemplateSingleValue,
	protocolTemplateLoadDefaults,
	fetchContractors,
	addContractor,
	updateContractor,
	deleteContractor,
	addOrdersPack,
	updateOrdersPack,
	deleteOrdersPack,
	fetchOrdersPack,
	fetchOrdersPacks,
	searchContractors,
	checkIsContractor,
	fetchProtocolsAndPhotos,
	downloadProtocolFile,
	fetchAvailableOrders,
	fetchOrdersInPack,
	fetchOrder,
	manageOrderFitters,
	cancelOrder,
	unfulfillOrder,
	fetchNote,
	addNote,
	editNote,
	deleteNote,
	fetchDetailedInventoryFile,
	fetchAggregatedInventoryFile,
	fetchCurrentOrdersInPack,
	assignFittersToOrders,
	fetchTags,
	addTagToOrder,
	removeTagFromOrder,
	addTag,
	fetchOrdersStatistics,
	archiveOrder,
	checkOrderCurrentOwnership,
	addOrder,
	shareProtocol
};

export default OrdersService;
