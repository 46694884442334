import { Box, Typography } from '@mui/material';
import { useLocale } from '@sonar-web/common/src/hooks';
import { DateRangeOptions } from '@sonar-web/common/src/features/DateRange/DateRangeOptions';
import DateRangeOwn from '@sonar-web/common/src/features/DateRange/DateRangeOwn';
import FiltersDateRangeTile from './FiltersDateRangeTile';
import useStatistics from '../useStatistics';

const options = [DateRangeOptions.Days1, DateRangeOptions.Days7, DateRangeOptions.Days30, DateRangeOptions.Own];

const FiltersDateRange = () => {
	const { translate } = useLocale();
	const { dateRange, updateDateRange } = useStatistics();
	const isOwn = dateRange.dateRangeOption === DateRangeOptions.Own.value;

	const handleSetOwn = (date) => updateDateRange(DateRangeOptions.Own.value, date.toISOString());

	return (
		<Box display='flex' flexDirection='column' rowGap={1} className='filtersSection'>
			<Typography variant='body2'>{translate('Stats_ShowReadsFrom')}</Typography>
			{options.map((o) => {
				return (
					<FiltersDateRangeTile
						key={o.value}
						value={o.value}
						title={`${o.description}Filter`}
						dataat={o.name}
					/>
				);
			})}
			<DateRangeOwn show={isOwn} onChange={handleSetOwn} initialOwnValue={new Date(dateRange.dateRangeDate)} />
		</Box>
	);
};

export default FiltersDateRange;
