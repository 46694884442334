import { lazy } from 'react';
import { Features } from '@sonar/auth';
import Module from './Constants/Module';

const ImportsGrid = lazy(() => import('./ImportsGrid'));
const GeneratedImports = lazy(() => import('./GeneratedImports'));
const NewImport = lazy(() => import('./NewImport'));

export default [
	{
		component: GeneratedImports,
		feature: Features.ImportWmbus.name,
		path: Module.routes.generatedImports(),
		exact: true,
		name: 'WaterMetersSetsImport'
	},
	{
		component: NewImport,
		feature: Features.ImportWmbusWizard.name,
		path: Module.routes.newImport(),
		exact: true,
		name: 'Common_ImportsWizard'
	},
	{
		component: ImportsGrid,
		feature: Features.ImportsPage.name,
		path: Module.routes.imports(),
		exact: true,
		name: 'Common_ImportsPage'
	}
];
