import { useContext } from 'react';
import MeasuringKind from '@sonar-web/common/src/enums/MeasuringKind';
import EventBus from '@sonar-web/common/src/helpers/EventBus';
import DashboardContentContext from '@sonar-web/common/src/components/Dashboard/dashboardContentContext';
import ButtonAdd from '@sonar-web/common/src/components/Buttons/ButtonAdd';
import Messages from '../Constants/Messages';
import ReadFormAggregated from './ReadFormAggregated';
import ReadForm from './ReadForm';

const ReadAdd = ({ location }) => {
	const { setCoverComponent } = useContext(DashboardContentContext);

	if (!location.currentSet) return null;

	const onClick = () => {
		if (isAggregated(location)) {
			const { main, additional } = getMainAndAdditional(location);

			setCoverComponent(
				<ReadFormAggregated
					main={main}
					additional={additional}
					startDate={location.currentSet.startDate}
					onClose={() => setCoverComponent(null)}
					onSuccess={() => {
						setCoverComponent(null);
						EventBus.dispatch(Messages.ManualReadAdded);
					}}
				/>
			);
			return;
		}

		setCoverComponent(
			<ReadForm
				watermeterId={location.currentSet.measurementPairs[0].inputDevice.id}
				startDate={location.currentSet.startDate}
				onClose={() => setCoverComponent(null)}
				onSuccess={() => {
					setCoverComponent(null);
					EventBus.dispatch(Messages.ManualReadAdded);
				}}
			/>
		);
	};

	return (
		<ButtonAdd name='read_add' onClick={onClick}>
			Read
		</ButtonAdd>
	);
};

export default ReadAdd;

const isAggregated = (location) => {
	return location.currentSet.measurementPairs.some((pair) => pair.measuringKind === MeasuringKind.Aggregated.name);
};

const getMainAndAdditional = (location) => {
	const main = location.currentSet.measurementPairs.find(
		(pair) => pair.measuringKind === MeasuringKind.Main.name
	)?.inputDevice;
	const additional = location.currentSet.measurementPairs.find(
		(pair) => pair.measuringKind === MeasuringKind.Additional.name
	)?.inputDevice;

	return { main, additional };
};
