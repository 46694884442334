import { createSlice } from '@reduxjs/toolkit';
import { fetchUser, addUser, editUser, deleteUser } from './userApi';
import { addErrorAsync } from '@sonar-web/common/src/features/Errors/errorsSlice';
import AuthService from '@sonar/auth/src/AuthService';
import AccessService from '../AccessService';

const slice = 'user';
const getUser = (getState) => getState().user.user;
const getClient = () => AuthService.getClientId();

export const initialUserData = {
	id: null,
	firstName: '',
	lastName: '',
	username: '',
	email: '',
	password: '',
	changePassword: true,
	enabled: true,
	clientRoles: [],
	groups: []
};

export const userSlice = createSlice({
	name: 'user',
	initialState: {
		error: null,
		addPending: false,
		addSuccess: false,
		fetchPending: false,
		fetchSuccess: false,
		deletePending: false,
		fetchByUsernameSuccess: false,
		user: initialUserData
	},
	reducers: {
		fetchUserSucces: (state, action) => {
			const groups = [...action.payload.groups];

			for (const g of groups) {
				let description = '';

				if (Object.keys(g.attributes).length > 0 && g.attributes.description)
					description = g.attributes.description[0];

				g.description = description;
			}

			state.user = {
				...action.payload,
				email: action.payload.email ?? '',
				password: '',
				changePassword: false,
				groups
			};
			state.fetchPending = false;
			state.fetchSuccess = true;
		},
		fetchUserPending: (state, { payload }) => {
			state.fetchPending = payload;
			state.fetchSuccess = false;
		},
		addUserSucces: (state) => {
			state.addPending = false;
			state.addSuccess = true;
		},
		addUserPending: (state, action) => {
			state.addPending = action.payload;
		},
		deleteUserPending: (state, { payload }) => {
			state.deletePending = payload;
		},
		setUserData: (state, { payload }) => {
			state.user = { ...payload, username: payload.username.toLowerCase() };
		},
		resetUserData: (state) => {
			state.user = initialUserData;
			state.addSuccess = false;
			state.fetchSuccess = false;
		}
	}
});

export const {
	fetchUserSucces,
	fetchUserPending,
	addUserSucces,
	addUserPending,
	deleteUserPending,
	setUserData,
	resetUserData
} = userSlice.actions;

export const fetchUserAsync = (id) => async (dispatch) => {
	try {
		dispatch(fetchUserPending(true));
		const response = await fetchUser(id, getClient());
		dispatch(fetchUserSucces(response));
	} catch (error) {
		dispatch(fetchUserPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const addUserAsync = () => async (dispatch, getState) => {
	try {
		dispatch(addUserPending(true));

		const user = { ...getUser(getState), clientId: getClient() };

		await addUser(user);
		dispatch(addUserSucces());
	} catch (error) {
		dispatch(addUserPending(false));
		return dispatch(
			addErrorAsync({
				slice,
				error,
				skipNotificationMembers: ['username', 'firstName', 'lastName', 'email', 'password']
			})
		);
	}
};

export const editUserAsync = () => async (dispatch, getState) => {
	try {
		dispatch(addUserPending(true));

		const user = { ...getUser(getState), clientId: getClient() };
		if (!user.changePassword) delete user.password;

		await editUser(user);
		dispatch(addUserSucces());
	} catch (error) {
		dispatch(addUserPending(false));
		return dispatch(
			addErrorAsync({
				slice,
				error,
				skipNotificationMembers: ['username', 'firstName', 'lastName', 'email', 'password']
			})
		);
	}
};

export const deleteUserAsync = (id) => async (dispatch) => {
	try {
		dispatch(deleteUserPending(true));
		await deleteUser(id);
		dispatch(deleteUserPending(false));
	} catch (error) {
		dispatch(deleteUserPending(false));
		dispatch(addErrorAsync({ slice, error }));
	}
};

export const assignUserToTenantAsync = () => async (dispatch, getState) => {
	try {
		dispatch(addUserPending(true));

		const user = { ...getUser(getState), clientId: getClient() };

		await AccessService.assignUserToTenant(user);
		dispatch(addUserSucces());
	} catch (error) {
		dispatch(addUserPending(false));
		return dispatch(
			addErrorAsync({
				slice,
				error,
				skipNotificationMembers: ['username', 'firstName', 'lastName', 'email', 'password']
			})
		);
	}
};

export const selectUser = (state) => {
	return {
		dataRow: state.user.user,
		fetchPending: state.user.fetchPending,
		fetchSuccess: state.user.fetchSuccess
	};
};

export const selectAddUser = (state) => {
	return {
		addPending: state.user.addPending,
		addSuccess: state.user.addSuccess
	};
};

export const selectDeleteUserPending = (state) => {
	return {
		deletePending: state.user.deletePending,
		deleteSuccess: state.user.deleteSuccess
	};
};

export default userSlice.reducer;
