import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Typography, Box, Fade } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useLocale } from '../../../hooks';
import { selectObjectSucessfullyDeleted, resetObjectSucessfullyDeleted } from '../../../appSlice';
import ButtonAdd from '../../Buttons/ButtonAdd';
import background from './success_scene.svg';

const useStyles = makeStyles((theme) => ({
	background: {
		width: '100%',
		height: '100%',
		maxHeight: 310,
		minHeight: 200,
		marginBottom: '4vh',
		marginTop: '9vh',
		background: `url(${background}) no-repeat center center`,
		backgroundSize: 'auto 100%'
	},
	primaryText: {
		color: theme.palette.text.primary,
		'& > span': {
			fontWeight: theme.typography.fontWeightMedium
		},
		marginTop: theme.spacing(3)
	},
	secondaryText: {
		color: theme.palette.text.secondary,
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(8)
	},
	primaryData: {
		fontWeight: theme.typography.fontWeightMedium
	},
	secondaryData: {
		color: theme.palette.grey[500],
		fontWeight: 'normal'
	}
}));
const DashboardDeleteSuccessPage = () => {
	const classes = useStyles();
	const { translate } = useLocale();
	const history = useHistory();

	const initialCounter = 5;
	const [counter, setCounter] = useState(initialCounter);
	const [objectDeleted, setObjectDeleted] = useState(null);
	const dispatch = useDispatch();

	const objectSucessfullyDeleted = useSelector(selectObjectSucessfullyDeleted);

	const handleBackToMainPage = () => {
		history.replace('/');
	};

	useEffect(() => {
		if (objectSucessfullyDeleted) {
			setObjectDeleted(objectSucessfullyDeleted);

			dispatch(resetObjectSucessfullyDeleted());
		} else if (!objectDeleted) {
			handleBackToMainPage();
		}

		if (counter == initialCounter) {
			setTimeout(() => {
				setInterval(() => {
					setCounter((prevCounter) => {
						return prevCounter == 1 ? 1 : prevCounter - 1;
					});
				}, 1200);
			}, 1000);
		}
	}, []);

	useEffect(() => {
		if (counter == 1) {
			setTimeout(() => {
				handleBackToMainPage();
			}, 1200);
		}
	}, [counter]);

	if (!objectDeleted) {
		return null;
	}

	return (
		<>
			<Fade in={true} timeout={1000}>
				<Box
					display='flex'
					flexDirection='column'
					alignItems='center'
					height='100%'
					data-at='successfullyRemoved'>
					<div className={classes.background} />
					<Typography
						variant='h5'
						classes={{
							root: classes.primaryText
						}}>
						{translate('Common_SuccessfullyRemoved')}
						{objectDeleted.name && <span> {translate(objectDeleted.name).toLowerCase()}</span>}
					</Typography>
					<Box>
						<Typography
							variant='h6'
							component='span'
							data-at={`deletedItem.primaryData`}
							className={`${classes.primaryData}`}>
							{objectDeleted.primaryData}
						</Typography>{' '}
						{objectDeleted.secondaryData ? (
							<Typography
								variant='h6'
								component='span'
								color='textSecondary'
								data-at={`deletedItem.secondaryData`}
								className={`${classes.secondaryData}`}>
								{`(${objectDeleted.secondaryData})`}
							</Typography>
						) : null}
					</Box>
					<Typography
						variant='subtitle1'
						classes={{
							root: classes.secondaryText
						}}>
						{translate('Common_RedirectToMainPageAfter')} {counter}
						{'...'}
					</Typography>
					<ButtonAdd
						data-at='backToMainPage'
						startIcon={<KeyboardBackspaceIcon />}
						onClick={handleBackToMainPage}>
						Common_BackToMainPage
					</ButtonAdd>
				</Box>
			</Fade>
		</>
	);
};

export default DashboardDeleteSuccessPage;
