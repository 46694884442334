import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLocale } from '@sonar-web/common/src/hooks';

const useStyles = makeStyles((theme) => ({
	secondaryText: {
		color: theme.palette.text.secondary,
		verticalAlign: 'middle',
		whiteSpace: 'nowrap'
	}
}));

const getRssiColor = (rssi) => {
	if (rssi > -70) return '#11A744';
	if (rssi >= -90) return '#FFB800';
	return '#DB2242';
};

const getAfcColor = (afc) => {
	if (afc < -10 || afc > 10) return '#DB2242';
	if (afc < -5 || afc > 5) return '#FFB800';
	return '#11A744';
};

const RssiAfcData = ({ rssi, afc }) => {
	const classes = useStyles();
	const { translate } = useLocale();

	const spaceChar = '\u00A0';
	const rightArrowChar = '\u27F6';
	const dotStyle = {
		borderRadius: '50%',
		display: 'inline-block',
		height: '6px',
		width: '6px',
		marginLeft: '4px',
		marginRight: '4px',
		marginBottom: '2px'
	};

	return (
		<Typography
			variant='body2'
			classes={{
				root: classes.secondaryText
			}}>
			{spaceChar}
			{rightArrowChar}
			{spaceChar}
			<span
				style={{
					background: getRssiColor(rssi),
					...dotStyle
				}}></span>
			{spaceChar}
			{translate('Rssi')} {rssi ?? '--'} {translate('Dbm')} {' | '}
			<span
				style={{
					background: getAfcColor(afc),
					...dotStyle
				}}></span>
			{spaceChar}
			{translate('Afc')} {afc ?? '--'} {translate('kHz')}
			{spaceChar}
			{rightArrowChar}
			{spaceChar}
		</Typography>
	);
};

export default RssiAfcData;
