import { pageDescriptor } from '@sonar-web/common';

export const levelTypesStateName = 'levelTypes';

export const levelTypesInitialState = {
	pageDescriptor: { ...pageDescriptor, SortDescriptors: [{ Member: 'LevelNo', ListSortDirection: 'Asc' }] },
	fetchPending: false,
	fetchSuccess: false,
	elements: []
};

export const levelTypesReducers = {
	setLevelTypesFetchSucces: (state, { payload }) => {
		state[levelTypesStateName].elements = payload;
		state[levelTypesStateName].pending = false;
		state[levelTypesStateName].fetchSuccess = true;
	},
	setLevelTypesFetchPending: (state, { payload }) => {
		state[levelTypesStateName].pending = payload;
	}
};
