import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from 'formik';
import useLocale from '@sonar-web/common/src/hooks/useLocale';
import { ERROR_TYPES } from '@sonar-web/common/src/constants/errorTypes';
import { FormTopActionsToolbar, BaseGrowAnimation, guidEmpty } from '@sonar-web/common';
import LocationNodeLevelAvatar from '../LocationNodeLevels/LocationNodeLevelAvatar';
import LocationNodeLevelName from '../LocationNodeLevels/LocationNodeLevelName';
import {
	setLocationNodeData,
	selectAddLocationNode,
	selectLocationNode,
	addLocationNodeAsync,
	resetLocationNodeData,
	resetAddSuccess
} from './slice';
import { setResetAndReload } from '../LocationNodes/slice';
import { nodelevelTypeLocalName } from '../constants';
import useValidation from './useValidation';

const useStyles = makeStyles((theme) => ({
	emphesizeText: {
		fontWeight: theme.typography.fontWeightMedium
	}
}));

const LocationNodeAdd = ({
	level,
	onClose,
	onSubmitDispatch,
	onSuccessCallback,
	currentBreadcrumb,
	disableSubmitMany,
	submitActionTooltip = 'Common_Confirm'
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { translate } = useLocale();
	const validationSchema = useValidation(level.type);
	const [submitMany, setSubmitMany] = React.useState(false);
	const { addPending, addSuccess } = useSelector(selectAddLocationNode);
	const { dataRow } = useSelector(selectLocationNode);
	const nameInput = useRef(null);

	const handleClose = (result) => {
		onClose(result);
	};

	const handleApiErrors = (err, formikActions) => {
		formikActions.setSubmitting(false);

		if (err && err.type === ERROR_TYPES.api) {
			formikActions.setFieldTouched(err.member.uncapitalize(), true);
			setTimeout(() => formikActions.setFieldError(err.member.uncapitalize(), err.message), 100);
		}
	};

	const handleOnSubmit = async (values, actions) => {
		actions.setSubmitting(true);

		const result = {
			...values,
			locationNodeLevelId: level.id,
			parentLocationNodeId: currentBreadcrumb.id
		};

		dispatch(setLocationNodeData(result));

		if (onSubmitDispatch) {
			const { action, actionOnSuccess } = await onSubmitDispatch();

			dispatch(action(submitMany, actionOnSuccess)).then((err) => {
				handleApiErrors(err, actions);
				if (submitMany) nameInput.current.focus();
			});
		} else {
			dispatch(addLocationNodeAsync(submitMany)).then((err) => {
				handleApiErrors(err, actions);
				if (submitMany) nameInput.current.focus();
			});
		}
	};

	const handleOnSuccess = (result) => {
		if (!submitMany) handleClose(result);
		else dispatch(resetLocationNodeData());

		setTimeout(() => {
			dispatch(setResetAndReload());
		}, 300);
	};

	useEffect(() => {
		return () => dispatch(resetLocationNodeData());
	}, []);

	if (!level || !currentBreadcrumb) return null;

	return (
		<BaseGrowAnimation show={true}>
			<Box flex='1' alignSelf='center' width={500} data-at='LocationNodeAdd'>
				<Formik
					initialValues={{ ...dataRow, hasZipCode: level.hasZipCode }}
					enableReinitialize
					validationSchema={validationSchema}
					onSubmit={handleOnSubmit}>
					{({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => {
						return (
							<>
								<form onSubmit={handleSubmit}>
									<FormTopActionsToolbar
										submitActionTooltip={
											typeof submitActionTooltip === 'function'
												? submitActionTooltip(level.type)
												: submitActionTooltip
										}
										avatar={() => <LocationNodeLevelAvatar level={level} />}
										title={
											!currentBreadcrumb
												? ''
												: currentBreadcrumb.id
												? 'LocationNodes_AddNewSubLocationNode'
												: 'LocationNodes_AddNewTopLocationNode'
										}
										subtitle={
											!currentBreadcrumb ? (
												''
											) : currentBreadcrumb.id !== guidEmpty ? (
												<>
													<Typography component='span' variant='body2'>
														<LocationNodeLevelName
															locationNodeLevelId={currentBreadcrumb.locationNodeLevelId}
														/>
													</Typography>{' '}
													<Typography
														component='span'
														variant='body2'
														className={classes.emphesizeText}>
														{currentBreadcrumb.name}
													</Typography>
												</>
											) : (
												''
											)
										}
										handleCancel={() => {
											handleClose();
										}}
										handleSubmit={handleSubmit}
										pending={addPending}
										success={addSuccess}
										onSuccess={() => {
											const result = {
												addedLocationId: addSuccess.id,
												addedLocationLevelType: addSuccess.id ? level.type : undefined
											};

											if (onSuccessCallback) onSuccessCallback(result, submitMany);
											else handleOnSuccess(result);
										}}
										resetSuccess={() => dispatch(resetAddSuccess())}
										submitMany={submitMany}
										setSubmitMany={setSubmitMany}
										disableSubmitMany={disableSubmitMany}
									/>
									<TextField
										autoFocus
										margin='normal'
										name='name'
										label={translate(level.name)}
										type='text'
										size='small'
										fullWidth
										inputRef={nameInput}
										value={values.name}
										onChange={handleChange}
										onBlur={handleBlur}
										error={errors.name && touched.name}
										helperText={errors.name && touched.name && translate(errors.name)}
										FormHelperTextProps={{
											'data-at-error': errors.name
										}}
									/>
									{level.hasZipCode && (
										<TextField
											margin='normal'
											name='zipCode'
											label={translate('LocationNodes_ZipCode')}
											type='text'
											size='small'
											fullWidth
											value={values.zipCode}
											onChange={handleChange}
											onBlur={handleBlur}
											error={errors.zipCode && touched.zipCode}
											helperText={errors.zipCode && touched.zipCode && translate(errors.zipCode)}
											FormHelperTextProps={{
												'data-at-error': errors.zipCode
											}}
										/>
									)}
									{level.type == nodelevelTypeLocalName && (
										<TextField
											margin='normal'
											name='externalId'
											label={translate('LocationNodes_ExternalId')}
											type='text'
											size='small'
											fullWidth
											value={values.externalId}
											onChange={handleChange}
											onBlur={handleBlur}
											error={errors.externalId && touched.externalId}
											helperText={
												errors.externalId && touched.externalId && translate(errors.externalId)
											}
											FormHelperTextProps={{
												'data-at-error': errors.externalId
											}}
										/>
									)}
								</form>
							</>
						);
					}}
				</Formik>
			</Box>
		</BaseGrowAnimation>
	);
};

export default LocationNodeAdd;
