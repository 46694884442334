import { Fade, Box } from '@mui/material';
import { usePanelContentContext } from './panelContentContext';

const PanelContentCover = () => {
	const { panelCover } = usePanelContentContext();

	return (
		<Fade in={panelCover != null} mountOnEnter unmountOnExit>
			<Box display='flex' flex={1} flexDirection='column' height='100%' width='100%'>
				{panelCover}
			</Box>
		</Fade>
	);
};

export default PanelContentCover;
