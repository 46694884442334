import React from 'react';
import { Toolbar } from '@mui/material';
import { Spacer, VerticalSpacer } from '@sonar-web/common';
import ButtonOnlyIconNext from '@sonar-web/common/src/components/Buttons/ButtonOnlyIconNext';
import IconButtonBack from '@sonar-web/common/src/components/Buttons/IconButtonBack';
import ButtonText from '@sonar-web/common/src/components/Buttons/ButtonText';
import useToolbar from './useToolbar';

const ConfiguratorControl = () => {
	const { getButtonsState } = useToolbar();
	const { back, next, cancel } = getButtonsState();

	return (
		<Toolbar variant='dense' disableGutters>
			<Spacer />
			<ButtonText name='ConfiguratorCancel' {...cancel}>
				Common_Cancel
			</ButtonText>
			{!back.hidden && <IconButtonBack name='ConfiguratorPrevious' {...back} />}
			{!next.hidden && (
				<>
					<VerticalSpacer size={2} />
					<ButtonOnlyIconNext name='ConfiguratorNext' {...next} />
				</>
			)}
		</Toolbar>
	);
};

export default ConfiguratorControl;
