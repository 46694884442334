import { apiBase } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.REPORTS;

async function fetchCurrentNumberOfReports() {
	const { data } = await apiBase(`currentNumberOfReports`, 'get', {
		path
	});

	return data;
}

const ReportsService = {
	fetchCurrentNumberOfReports
};

export default ReportsService;
