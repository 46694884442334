import { Box, Skeleton } from '@mui/material';

const BuildingVisualizationSkeleton = () => {
	return (
		<Box overflow='auto'>
			{[1, 2, 3, 4].map((_, index) => (
				<Floor key={index} />
			))}
		</Box>
	);
};

export default BuildingVisualizationSkeleton;

function Local() {
	return (
		<Skeleton
			animation='wave'
			variant='rectangular'
			height={120}
			width={120}
			style={{ marginRight: 4, marginBottom: 4 }}
		/>
	);
}

function Floor() {
	return (
		<Box display='flex'>
			<Local />
			<Local />
			<Local />
			<Local />
		</Box>
	);
}
