import { ROUTE_GUID } from '@sonar-web/common';

export const moduleName = 'orders';

export const slices = {
	order: 'order',
	currentOrders: 'currentOrders',
	tasks: 'tasks',
	task: 'task',
	orderStatistics: 'orderStatistics',
	availableLocations: 'availableLocations',
	currentLocations: 'currentLocations',
	currentPointsInOrder: 'currentPointsInOrder',
	archivedOrders: 'archivedOrders',
	protocolTemplate: 'protocolTemplate',
	contractors: 'contractors',
	ordersPacks: 'ordersPacks',
	availableOrders: 'availableOrders',
	ordersInPack: 'ordersInPack'
};

export const routes = {
	currentOrders: () => '/orders/current',
	archivedOrders: () => '/orders/archived',
	orderNew: () => '/order',
	orderCopy: (props) => (props ? `/order/${props.orderId}/copy` : `/order/:orderId(${ROUTE_GUID})/copy`),
	orderPage: (props) => (props ? `/order/${props.orderId}` : `/order/:orderId(${ROUTE_GUID})`),
	protocolTemplate: () => '/protocol/template',
	contractors: () => '/orders/contractors',
	orderedOrdersPacks: () => '/orderedOrdersPacks',
	receivedOrdersPacks: () => '/receivedOrdersPacks',
	ordersPackPage: (props) =>
		props ? `/ordersPack/${props.ordersPackId}` : `/ordersPack/:ordersPackId(${ROUTE_GUID})`,
	ordersPackPageSimple: (props) =>
		props ? `/ordersPack/${props.ordersPackId}/simple` : `/ordersPack/:ordersPackId(${ROUTE_GUID})/simple`
};
