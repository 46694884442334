import { lazy } from 'react';

const HelpCenterPage = lazy(() => import('./HelpCenterPage'));

export default [
	{
		component: HelpCenterPage,
		path: '/helpcenter',
		exact: true,
		name: 'Common_HelpCenter'
	}
];
