import { useEffect } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { fetchBuildingVisualizationAsync, selectBuildingVisualization } from './buildingVisualizationActions';
import BuildingVisualizationSkeleton from './BuildingVisualizationSkeleton';
import NoBuildingVisualization from './NoBuildingVisualization';
import useBuilingVisualization from './useBuilingVisualization';
import Building from './Building';
import useStatistics from '../../Statistics/useStatistics';
import BuildingFilters from './BuildingFilters';
import BuildingControls from './BuildingControls';

const Wrapper = styled('div')({
	display: 'flex',
	height: '100%',
	minHeight: '0%',
	flexDirection: 'column',
	'& .buildingToolbar': {
		marginBottom: '2rem'
	}
});

const BuildingVisualization = ({ location }) => {
	const dispatch = useDispatch();
	const { dateRange, layer, updateDateRange, resetStats } = useStatistics();
	const { getDateForOption } = useBuilingVisualization();
	const { data, pending } = useSelector(selectBuildingVisualization);

	useEffect(() => {
		if (!location || !dateRange.dateRangeDate) return;

		dispatch(fetchBuildingVisualizationAsync(location.id, { layer, dateRangeDate: dateRange.dateRangeDate }));
	}, [layer, dateRange.dateRangeDate]);

	useEffect(() => {
		if (dateRange.dateRangeDate) return;

		updateDateRange(dateRange.dateRangeOption, getDateForOption(dateRange.dateRangeOption.value));

		return () => {
			if (!mapView) resetStats();
		};
	}, []);

	return <Wrapper>{pending ? <BuildingVisualizationSkeleton /> : <Visualization data={data} />}</Wrapper>;
};

export default BuildingVisualization;

const Visualization = ({ data }) => {
	if (!data) return <NoBuildingVisualization />;
	if (!checkStructureIsOk(data)) return <NoBuildingVisualization />;
	return (
		<>
			<Box display='flex' className='buildingToolbar' gap='.5rem'>
				<BuildingFilters />
				<BuildingControls />
			</Box>
			<Building />
		</>
	);
};

function checkStructureIsOk(data) {
	if (data.length === 0) return false;

	const hasFloors = data[0].floors?.length > 0;
	if (!hasFloors) return false;

	return data[0].floors[0].locals?.length > 0;
}
