import * as Yup from 'yup';
import validationMessages from './validationMessages';

export const YupSchemaDefinitions = {
	/**
	 * Tekst krótki
	 * @param {object} overrides
	 * @param {boolean} checkWhitespace
	 * @returns obiekt Yup
	 */
	shortString: (overrides = {}, checkWhitespace = false) => {
		const options = {
			min: { value: 3, message: validationMessages.shortStringMin },
			max: { value: 50, message: validationMessages.shortStringMax },
			req: { value: true, message: validationMessages.required },

			...overrides
		};
		const { min, max, req } = options;

		let baseYup = Yup.string().min(min.value, min.message).max(max.value, max.message);

		if (checkWhitespace) baseYup = baseYup.matches(/^[^\s].+[^\s]$/, validationMessages.whitespace);

		if (options.req.value) return baseYup.required(req.message);
		return baseYup;
	},

	/**
	 * Tekst krótki
	 * @param {object} overrides
	 * @param {boolean} checkWhitespace
	 * @returns obiekt Yup
	 */
	longString: (overrides = {}, checkWhitespace = false) => {
		const options = {
			min: { value: 1, message: validationMessages.longStringMin },
			max: { value: 150, message: validationMessages.longStringMax },
			req: { value: true, message: validationMessages.required },
			...overrides
		};
		const { min, max, req } = options;

		let baseYup = Yup.string().min(min.value, min.message).max(max.value, max.message);

		if (checkWhitespace) baseYup = baseYup.matches(/^[^\s].+[^\s]$/, validationMessages.whitespace);

		if (options.req.value) return baseYup.required(req.message);
		return baseYup;
	},

	/**
	 * Stan początkowy zestawu - decimal
	 * @param {object} overrides
	 * @returns obiekt Yup
	 */
	initialStateDecimal: (translate, overrides = {}) => {
		const options = {
			req: { value: false, message: validationMessages.required },
			...overrides
		};
		const { req } = options;

		let baseYup = Yup.string().matches(
			/^(?=.*\d)\d{1,8}(?:[\,|\.]\d{1,3})?$/,
			translate('MeasurementSet_InitialStateValidation')
				.replace('{0}', '0')
				.replace('{1}', '99999999,999'.replace(',', translate('MeasurementSet_DecimalSeparator')))
		);

		if (options.req.value) return baseYup.required(req.message);
		return baseYup;
	},

	/**
	 * Stan początkowy zestawu ujemny - decimal
	 * @param {object} overrides
	 * @returns obiekt Yup
	 */
	initialStateDecimalNegative: (translate, overrides = {}) => {
		const options = {
			req: { value: false, message: validationMessages.required },
			...overrides
		};
		const { req } = options;

		let baseYup = Yup.string().matches(
			/^-?(?=.*\d)\d{1,8}(?:[\,|\.]\d{1,3})?$/,
			translate('MeasurementSet_InitialStateValidation')
				.replace('{0}', '-99999999,999')
				.replace('{1}', '99999999,999'.replace(',', translate('MeasurementSet_DecimalSeparator')))
		);

		if (options.req.value) return baseYup.required(req.message);
		return baseYup;
	},

	/**
	 * Stan końcowy zestawu - decimal
	 * @param {object} overrides
	 * @returns obiekt Yup
	 */
	endStateDecimal: (translate, overrides = {}) => {
		const options = {
			req: { value: false, message: validationMessages.required },
			...overrides
		};
		const { req } = options;

		let baseYup = Yup.string().matches(
			/^(?=.*\d)\d{1,8}(?:[\,|\.]\d{1,3})?$/,
			translate('MeasurementSet_EndStateValidation')
				.replace('{0}', '0')
				.replace('{1}', '99999999,999'.replace(',', translate('MeasurementSet_DecimalSeparator')))
		);

		if (options.req.value) return baseYup.required(req.message);
		return baseYup;
	},

	/**
	 * Stan końcowy zestawu ujemny - decimal
	 * @param {object} overrides
	 * @returns obiekt Yup
	 */
	endStateDecimalNegative: (translate, overrides = {}) => {
		const options = {
			req: { value: false, message: validationMessages.required },
			...overrides
		};
		const { req } = options;

		let baseYup = Yup.string().matches(
			/^-?(?=.*\d)\d{1,8}(?:[\,|\.]\d{1,3})?$/,
			translate('MeasurementSet_EndStateValidation')
				.replace('{0}', '-99999999,999')
				.replace('{1}', '99999999,999'.replace(',', translate('MeasurementSet_DecimalSeparator')))
		);

		if (options.req.value) return baseYup.required(req.message);
		return baseYup;
	},

	/**
	 * Stan początkowy zestawu - integer
	 * @param {object} overrides
	 * @returns obiekt Yup
	 */
	initialStateInt: (translate, overrides = {}) => {
		const options = {
			req: { value: false, message: validationMessages.required },
			...overrides
		};
		const { req } = options;

		let baseYup = Yup.string().matches(
			/^(?=.*\d)\d{1,8}$/,
			translate('MeasurementSet_InitialStateValidationInteger').replace('{0}', '0').replace('{1}', '99999999')
		);

		if (options.req.value) return baseYup.required(req.message);
		return baseYup;
	},

	/**
	 * Stan końcowy zestawu - integer
	 * @param {object} overrides
	 * @returns obiekt Yup
	 */
	endStateInt: (translate, overrides = {}) => {
		const options = {
			req: { value: false, message: validationMessages.required },
			...overrides
		};
		const { req } = options;

		let baseYup = Yup.string().matches(
			/^(?=.*\d)\d{1,8}$/,
			translate('MeasurementSet_EndStateValidationInteger').replace('{0}', '0').replace('{1}', '99999999')
		);

		if (options.req.value) return baseYup.required(req.message);
		return baseYup;
	},

	/**
	 * Stan początkowy zestawu - int
	 * @param {object} overrides
	 * @returns obiekt Yup
	 */
	correction: (translate, overrides = {}) => {
		const options = {
			req: { value: false, message: validationMessages.required },
			...overrides
		};
		const { req } = options;

		let baseYup = Yup.string().matches(
			/^\-?(?=.*\d)\d{1,8}(?:[\,|\.]\d{1,3})?$/,
			translate('MeasurementSet_CorrectionValidationDecimal')
		);

		if (options.req.value) return baseYup.required(req.message);
		return baseYup;
	},

	/**
	 * Stan początkowy zestawu - int
	 * @param {object} overrides
	 * @returns obiekt Yup
	 */
	sealNumbers: (translate, overrides = {}) => {
		const options = {
			req: { value: false, message: validationMessages.required },
			...overrides
		};
		const { req } = options;

		let baseYup = Yup.string().max(20, 'MeasurementSet_SealValidation');

		if (options.req.value) return baseYup.required(req.message);
		return baseYup;
	}
};
