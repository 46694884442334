import { useMemo } from 'react';
import { useLocale } from '@sonar-web/common/src/hooks';
import useStatistics from './useStatistics';
import StatsHelper from '../Helpers/StatsHelper';
import StatisticsSettingsItem from './StatisticsSettingsItem';
import LayerTypeIcon from '../Icons/LayerTypeIcon';

const StatisticsSettingsLayer = () => {
	const { translate } = useLocale();
	const { layer } = useStatistics();

	const { text, type } = useMemo(() => {
		const key = StatsHelper.getSettingsLayerTextByInterfaceType(layer);
		return { type: key, text: translate(key) };
	}, [layer]);

	return <StatisticsSettingsItem Icon={LayerTypeIcon} text={text} dataat={`deviceOption|${type}`} />;
};

export default StatisticsSettingsLayer;
